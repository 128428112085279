import { PLUGIN } from "_constants/request.constants";
import { reduxActions as MULTIVENDE } from "../multivende.constants";

export const config = (extras = {}) => (dispatch) => dispatch({
  [PLUGIN]: {
    types: [MULTIVENDE.CONFIG_STARTED, MULTIVENDE.CONFIG_SUCCESS, MULTIVENDE.CONFIG_FAIL],
    endpoint: '/plugin/multivende',
    reqType: 'GET',
    ...extras
  }
});

export const post = (data, $config = { }, extras = {}) => 
  (dispatch) => dispatch({
    [PLUGIN]: {
      types: [MULTIVENDE.POST_STARTED, MULTIVENDE.POST_SUCCESS, MULTIVENDE.POST_FAIL],
      endpoint: '/plugin/multivende',
      reqType: 'POST',
      data: {
        ...data,
        $config
      },
      ...extras
    }
  });

  export const conn = (data, $config = { }, extras = {}) => 
    (dispatch) => dispatch({
      [PLUGIN]: {
        types: [MULTIVENDE.AUTHORIZE_STARTED, MULTIVENDE.AUTHORIZE_SUCCESS, MULTIVENDE.AUTHORIZE_FAIL],
        endpoint: '/plugin/multivende/authorize',
        reqType: 'POST',
        data: {
          ...data,
          $config
        },
        ...extras
      }
    });
