import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { find, findOne, post, put } from '_redux/product/product.actions';
import { findOne as findTask } from '_redux/task/task.actions';
import { FAILURE, IDLE, LOADING, SUCCESS } from '_constants/redux.constants';
import { selectAppComponentDrawing } from '_redux/app/app.selector';
import {
  selectProductAiAnalysis,
  selectProductCanvasEditor,
  selectProductDetailsStore,
  selectProductLayoutData,
  selectProductPutStore
} from '_redux/product/product.selector';
import { getDrawFields } from '_utils/drawing';
import { reduxActions as PRODUCT } from '_constants/product.constants';
import urlToJson from '_utils/url/urlToJson';
import { NBSK_API } from '_constants/request.constants';

const useProduct = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const cDrawing = useSelector(selectAppComponentDrawing);

  const {
    data: aiAnalysisData,
    error: aiAnalysisError,
    status: aiAnalysisStatus
  } = useSelector(selectProductAiAnalysis);

  const { 
    data: detailsData, 
    error: detailsError,
    status: detailsStatus
  } = useSelector(selectProductDetailsStore);

  const { 
    data: layoutData, 
    error: layoutDataError,
    status: layoutDataStatus
  } = useSelector(selectProductLayoutData);

  const {
    data: putData,
    error: putError,
    status: putStatus
  } = useSelector(selectProductPutStore);

  let canvasEditor = useSelector(selectProductCanvasEditor);

  const [status, setStatus] = useState(IDLE);
  const [product, setProduct] = useState();

  /**
   * 
   * @param {Object} object
   * @returns 
   */
  const aiAnalysisProduct = ({ folderId, fileId, adaptationLayerId, $config, ...props }, extras = {}) => {
    return dispatch(post({
        c: [{ folderId, fileId, adaptationLayerId, ...props }],
        $config
      }, [
        PRODUCT.AI_ANALYSIS_STARTED,
        PRODUCT.AI_ANALYSIS_SUCCESS,
        PRODUCT.AI_ANALYSIS_FAIL
      ], {
        endpoint: "/folder/generated",
        ...extras
      }
    ));
  };


  const closeCanvasEditor = () => {
    dispatch({ type: PRODUCT.CANVAS_EDITOR_TOGGLE, payload: false });
    setTimeout(() => dispatch(
      { type: PRODUCT.CANVAS_EDITOR_DATA, payload: null }
    ), 100);
  };

  const getProduct = async (id, key = '_id', params, element = null, extras = {}) => {
    if (status === LOADING) return;
    let request = {
      ...params ?? {},
      w: {
        ...params?.w ?? {},
        [key]: id
      },
      a: {
        ...params?.a,
        extended: {
          ...params?.a?.extended ?? {},
          f: {
            ...params?.a?.extended?.f ?? {},
            filesId: [
              ...params?.a?.extended?.f?.filesId ?? [],
              ...[
                "adaptationId",
                "fileMasterId",
                "baseKeyS3Carousel",
                "baseKeyS3Thumbnail",
              ]
            ],
          }
        }
      }
    }

    if (element !== null) {
      request.f = [...request?.f ?? [], ...(getDrawFields(cDrawing, element) ?? [])];
    }

    let response = await dispatch(findOne(request, extras));

    let output = response?.data?.[0] ?? null;
    setProduct(output);
  };

  /**
   * 
   * @param {Object} data 
   * @param {Object} extras 
   * @returns 
   */
  const create = (data, $config, extras = {}) => dispatch(post({ c: data, $config }, null, {
    ...extras,
    endpoint: "/folder"
  }));


  const getRail = async (conditions) => {
    let response = await dispatch(find(conditions, {
      types: [
        PRODUCT.DETAILS_RAIL_STARTED,
        PRODUCT.DETAILS_RAIL_SUCCESS,
        PRODUCT.DETAILS_RAIL_FAIL
      ]
    }));

    return response;
  };

  const refreshList = (products) => dispatch({
    type: PRODUCT.ALL_REFRESH,
    payload: Array.isArray(products) ? products : [products]
  });

  const reset = () => {
    if (product === undefined) return;
    setStatus(IDLE);
    setProduct();
  };

  /**
   * 
   * @param {String} layoutKey 
   * @param {object} params 
   * @param {Object} extras 
   * @returns 
   */
  const fetchLayoutData = (layoutKey, params, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        PRODUCT.LAYOUT_STARTED,
        PRODUCT.LAYOUT_SUCCESS,
        PRODUCT.LAYOUT_FAIL
      ];
    }
    return dispatch({
      [NBSK_API]: {
        endpoint: `/folder/layout/${layoutKey}`,
        params,
        ...extras
      }
    });
  }

  /**
   * 
   * @param {String} key 
   * @returns 
   */
  const fetchProductDetails = async (key) => {
    let config = {
      f: ["key", "adaptationMastersId", "data", "process", "filesId", "layersId", "plugins"],
      a: {
        extended: {
          f: {
            filesId: [
              "_id",
              "adaptationId",
              "baseKeyS3Thumbnail",
              "baseKeyS3Carousel",
              "baseKeyS3Original",
              "data",
              "fileMasterId",
              "mimeType",
              "naming",
              "createdAt",
              "updatedAt"
            ],
            layersId: [
              "_id",
              "adaptationId",
              "adaptationLayerId",
              "baseKeyS3Thumbnail",
              "baseKeyS3Carousel",
              "baseKeyS3Original",
              "data",
              "fileMasterId",
              "mimeType",
              "naming",
              "createdAt",
              "updatedAt"
            ]
          },
        },
      },
    };

    let { from } = await urlToJson(location.search, true);
    if (from?.taskKey) {
      await dispatch(findTask({ f: ["_id", "key"], w: { key: from.taskKey }}))
      config = {
        ...config ?? {},
        f: [
          ...config?.f ?? [],
          ...["key", "tasksId"]
        ],
        a: {
          ...config?.a ?? {},
          extended: {
            ...config?.a?.extended ?? {},
            f: {
              ...config?.a?.extended?.f ?? {},
              tasksId: ["data"],
            },
            w: {
              tasksId: {
                taskKey: from.taskKey
              }
            }
          }
        },
      };
    }
    
    return getProduct(key, "key", config, null, {
      types: [
        PRODUCT.DETAILS_STARTED,
        PRODUCT.DETAILS_SUCCESS,
        PRODUCT.DETAILS_FAIL
      ]
    });
  };

  /**
   * 
   * @param {String} layout 
   * @param {Object|Array} data 
   * @returns 
   */
  const saveLayoutData = (layoutKey, productId, data, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        PRODUCT.LAYOUT_SAVE_STARTED,
        PRODUCT.LAYOUT_SAVE_SUCCESS,
        PRODUCT.LAYOUT_SAVE_FAIL
      ];
    }
    
    return dispatch(post({
      w: Array.isArray(productId) ? productId : [productId],
      c: Array.isArray(data) ? data : [data]
    }, null, {
      endpoint: `/folder/layout/${layoutKey}`,
      reqType: "POST",
      feedback: {
        error: true,
        success: true
      },
      ...extras,
      after: {
        attributeKey: Object.keys(data.data)?.pop(),
        layoutKey: layoutKey
      }
    }));
  };

  const layoutDataReset = () => dispatch({ type: PRODUCT.LAYOUT_RESET });

  const openCanvasEditor = () => dispatch({ type: PRODUCT.CANVAS_EDITOR_TOGGLE, payload: true });

  useEffect(() => {
    if (product === undefined) return;

    if (product !== null) {
      setStatus(SUCCESS);
    } else {
      setStatus(FAILURE);
    }
  }, [product]);

  const setLayerData = (data) => dispatch(
    { type: PRODUCT.CANVAS_EDITOR_DATA, payload: data }
  );

  const updateProduct = (keys, data) => dispatch(put(keys, data));
  

  /** RESETS */
  const aiAnalysisReset = () => dispatch({ type: PRODUCT.AI_ANALYSIS_RESET });

  return {
    aiAnalysisData,
    aiAnalysisError,
    aiAnalysisReset,
    aiAnalysisStatus,
    aiAnalysisProduct,
    canvasEditor,
    closeCanvasEditor,
    create,
    fetchLayoutData,
    fetchProductDetails,
    layoutData,
    layoutDataError,
    layoutDataReset,
    layoutDataStatus,
    getRail,
    getProduct,
    openCanvasEditor,
    product,
    detailsData,
    detailsError,
    detailsStatus,
    putData,
    putError,
    putStatus,
    refreshList,
    reset,
    saveLayoutData,
    setLayerData,
    status,
    updateProduct
  };
};

export default useProduct;
