import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const CircleStatus = ({ status }) => {
  const classForComponent = classNames("a-circle-status", {
    "is-complete": ["approved", "complete"].includes(status),
    "is-incomplete": ["toReview", "incomplete"].includes(status),
    "is-with-error":  ["rejected", "error"].includes(status),
    "is-empty": ["empty", "required", undefined].includes(status),
  });
  return <div className={classForComponent}></div>;
};

export default CircleStatus;

CircleStatus.propTypes = {
  status: PropTypes.oneOf([
    'approved', 'complete', 'error', 'incomplete', 'toReview', 'rejected', 'required'
  ])
}
