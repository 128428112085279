import { IDLE } from "_constants/redux.constants";

const initialProductState = {
  aiAnalysis: {
    data: null,
    error: null,
    status: IDLE
  },
  all: {
    data: null,
    error: null,
    status: IDLE
  },
  allList: {
    data: null,
    error: null,
    status: IDLE
  },
  analyze: {
    data: [],
    error: null,
    status: IDLE
  },
  analizeId: {
    data: null,
    error: null,
    status: IDLE
  },
  canvasEditor: {
    visible: false,
    data: null
  },
  count: {
    data: null,
    error: null,
    status: IDLE
  },
  countByField: {
    data: null,
    error: null,
    status: IDLE
  },
  countPiece: {
    data: null,
    error: null,
    status: IDLE
  },
  details: {
    data: null,
    error: null,
    status: IDLE
  },
  detailsRail: {
    data: null,
    error: null,
    status: IDLE
  },
  download: {
    data: null,
    error: null,
    status: IDLE
  },
  gallery: {
    images: {}
  },
  layoutData: {
    data: null,
    error: null,
    status: IDLE
  },
  layoutDataSave: {
    data: null,
    error: null,
    status: IDLE
  },
  one: {
    data: null,
    error: null,
    status: IDLE
  },
  put: {
    data: null,
    error: null,
    status: IDLE
  },
  post:{ 
    data: null,
    error: null,
    status: IDLE
  },
  remove: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialProductState;
