import { useState } from "react";
import { InputTest } from "components/molecules/Inputs";
import { FormInput } from "components/molecules/Form";
import { Button } from "components/atoms";
import { useTranslation } from "react-i18next";
import { useFocus, useForm, useLayer, useModal, useUser } from "_hooks";
import { NUBAI_MODULE, reduxActions as APP } from "_constants/app.constants";
import { HERO, AI, ISOMETRIC, SHOW_TYPES } from "_constants/layer.constants";

const prefix = "o-add-layer-modal";

const AddLayerModal = () => {
  const [layer] = useState();

  const { fetchList: fetchLayers } = useLayer();
  
  const { disable, error, form, handleChange } = useForm({
    schemaType: "newLayer",
    formInitialState: {
      name: "",
      type: HERO
    },
    layer
  });
  const { createLayer } = useLayer();
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { hasModule } = useUser();
  
  const [inputRef] = useFocus();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (form?.type === AI)  {
      form.property = {
        canvas: {
          height: 1024,
          width: 1024
        }
      };
    }
    
    createLayer(form).then(() => {
      fetchLayers({
        a: { sort: { by: { createdAt: -1 }}}
      }, {
        types: [
          APP.LAYERS_STARTED,
          APP.LAYERS_SUCCESS,
          APP.LAYERS_FAIL
        ]
      });
      closeModal();
    });
  };

  return (
    <div className={prefix}>
      <div className={`${prefix}__division`}></div>

      <div className={`${prefix}__header`}>
        <span>
          {t("settings:layers.addModal.fields.name.label")}
        </span>

        <FormInput
          inputprops={{
            checked: form?.type === HERO,
            type: "radio"
          }}
          label={SHOW_TYPES[HERO]}
          name="type"
          onChange={({ e }) => handleChange(e)}
          orientation="horizontal"
          reverse
          size="sm"
          value={HERO}
        />

        <FormInput
          inputprops={{
            checked: form?.type === ISOMETRIC,
            type: "radio"
          }}
          label={SHOW_TYPES[ISOMETRIC]}
          name="type"
          onChange={({ e }) => handleChange(e)}
          orientation="horizontal"
          reverse
          size="sm"
          value={ISOMETRIC}
        />

        {hasModule(NUBAI_MODULE) && 
          <FormInput  
            inputprops={{
              checked: form?.type === AI,
              type: "radio"
            }}
            label={SHOW_TYPES[AI]}
            name="type"
            onChange={({ e }) => handleChange(e)}
            orientation="horizontal"
            reverse
            size="sm"
            value={AI}
          />
        }
      </div>

      <InputTest
        ref={inputRef}
        name="name"
        onChange={handleChange}
        error={error.name}
      />

      <div className={`${prefix}__buttons`}>
        <Button 
          content={t("common:form.cancel")}
          onClick={closeModal}
          type="underline"
        />
        <Button
          content={t("settings:layers.addModal.buttons.create")}
          type="purple"
          sm
          disabled={disable}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AddLayerModal;
