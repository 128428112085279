import { LOADING } from "_constants/redux.constants";
import { Button, NubeskIcons } from "components/atoms/index";
import moment from "../../../../node_modules/moment/moment";

export const noMastersComponent = <>
  <div className="--datetime">Sin imágenes</div>
  <div className="--status"></div>
</>;

export const masterNoApprovedComponent = <>
  <div className="--datetime">Falta aprobación</div>
  <div className="--status"></div>
</>;

export const mvPendingComponent = ({
  _name,
  adaptationId,
  bussy,
  countFiles,
  generatePublish,
  marketplaceId,
  product,
  publishHandler,
  publishStatus
}) => <>
  <div className="--datetime"></div>
  <div className="--status">
    {!countFiles ?
      <Button
        content="Generar y publicar"
        disabled={bussy}
        onClick={e => generatePublish(e, { _name, adaptationId, marketplaceId })}
        type="primary" xs fullwidth 
      />
    :
      (publishStatus === LOADING && bussy === marketplaceId ?
        <NubeskIcons icon="loading" className="u-spin" size="2.5rem" />
      :
        <Button
          content="Publicar"
          disabled={bussy}
          onClick={(e) => {
            e.stopPropagation();
            publishHandler({
              folderId: product?._id,
              multivendeMarketplaceId: marketplaceId
            })
          }}
          type="primary" xs fullwidth />
        )
      }
  </div>
</>;

export const mvPushedComponent = ({
  adaptationId,
  bussy,
  countFiles,
  marketplaceId,
  plugin,
  product,
  publishHandler,
  publishStatus,
  withChanges
}) => <>
  <div className="--datetime">
    {withChanges ? "Hay cambios" : moment(plugin?.pushedAt).format("DD/MM/YY h:mm") }
  </div>
  <div className="--status">
    {withChanges || (withChanges && !countFiles ) ? 
      (publishStatus === LOADING && bussy === marketplaceId ?
        <NubeskIcons icon="loading" className="u-spin" size="2.5rem" />
      :
        <Button
          content="Publicar"
          disabled={bussy}
          fullwidth
          onClick={(e) => {
            e.stopPropagation();
            publishHandler({
              folderId: product?._id,
              multivendeMarketplaceId: marketplaceId
            })}
          }
          type="primary"
          xs
        />
      )
    : "Publicado"}
  </div>
</>;

export const emptyChannelConfigComponent = ({ history }) => <div className="--disconnected">
  <NubeskIcons icon="refresh" size="3rem" />
  <div>Plugin no configurado</div>
  <div>
    <Button
      content="Conectar aquí"
      onClick={() => history.push("/settings/plugins")}
      type="primary"
    />
  </div>
</div>;;

export const noSyncComponent = ({ createMV, postStatus }) => <div className="--disconnected">
  <NubeskIcons icon="refresh" size="3rem" />
  <div>Producto no sincronizado</div>
  <div>
    <Button
      content="Crear producto en Multivende"
      disabled={postStatus === LOADING}
      onClick={createMV}
      type="primary"
    />
  </div>
</div>;

export const disconectedComponent = ({ history }) => <div className="--disconnected">
  <NubeskIcons icon="disconnected" size="3rem" />
  <div>Plugin desconectado</div>
  <div>
    <Button
      content="Revisa la conexión aquí"
      onClick={() => history.push("/settings/plugins")}
      type="underline"
    />
  </div>
</div>
