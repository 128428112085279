import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { 
  BillingToolbar,  
  CatalogIdToolbar,
  ChannelAdaptationsToolbar,
  ChannelAttributesToolbar,
  DetailsToolbar,
  HomeToolbar,
  LayersToolbar,
  PropertyToolbar,
  TaskItemsToolbar,
  TaskToolbar,
  UsersPanelToolbar
} from "components/organism/Toolbars";
import {
  SalesChannelToolbar,
  SelectionCatalogsToolbar,
  SelectionToolbar
} from "components/organism";
import { selectCurrentToolbar } from "_redux/ui/ui.selector";


const Toolbar = () => {
  let renderedToolbar;
  const prefix = "t-toolbar";
  const currentToolbar = useSelector(selectCurrentToolbar);
  const classForComponent = classNames(prefix, {
    [`${prefix}__${currentToolbar}`]: currentToolbar,
  });

  const toolbarManager = {
    billing: BillingToolbar,
    catalogId: CatalogIdToolbar,
    catalogIdSelection: SelectionCatalogsToolbar,
    catalogsSelection: SelectionCatalogsToolbar,
    channelAdaptations: ChannelAdaptationsToolbar,
    channelAttributes: ChannelAttributesToolbar,
    details: DetailsToolbar,
    products: HomeToolbar,
    productsSelection: SelectionToolbar,
    property: PropertyToolbar,
    layers: LayersToolbar,
    salesChannel: SalesChannelToolbar,
    tasks: TaskToolbar,
    taskItems: TaskItemsToolbar,
    usersPanel: UsersPanelToolbar
  };

  if (typeof toolbarManager[currentToolbar] !== "undefined") {
    const ToolbarComponent = toolbarManager[currentToolbar];
    renderedToolbar = <ToolbarComponent />;
  }

  return <div className={classForComponent}>{renderedToolbar}</div>;
};

export default Toolbar;
