import React from "react";
import { Button, NubeskIcons } from "components/atoms";
import useTooltip from "_hooks/useTooltip";

const GridSelector = () => {
  const prefix = "m-grid-selector";
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });

  return (
    <div className={prefix} {...injectedProps}>
      <Button
        className={`${prefix}__filters`}
        content={<NubeskIcons icon="grid" />}
        onClick={() => {}}
        isActive
        disabled
      />
      <Button
        className={`${prefix}__filters`}
        content={<NubeskIcons icon="list" />}
        onClick={() => {}}
        style={{ color: "var(--color-purple)" }}
        disabled
      />
    </div>
  );
};

export default GridSelector;
