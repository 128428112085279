import { reduxActions as UI } from "_constants/ui.constants";

const manageImages = {
  [UI.MANAGE_IMAGES_DELETE]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      idsSorted: state.manageImages.idsSorted.filter(
        (id) => !payload.includes(id)
      ),
      initialSort: state.manageImages.initialSort.filter(
        (id) => !payload.includes(id)
      ),
      selection: [],
    },
  }),
  [UI.MANAGE_IMAGES_ADD_SELECTION]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      selection: [...state.manageImages.selection, payload],
    },
  }),
  [UI.MANAGE_IMAGES_REMOVE_SELECTION]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      selection: [
        ...state.manageImages.selection.filter((id) => id !== payload),
      ],
    },
  }),
  [UI.MANAGE_IMAGES_OPEN]: (state) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      isOpen: true,
    },
  }),
  [UI.MANAGE_IMAGES_CLOSE]: (state) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      isOpen: false,
    },
  }),
  [UI.MANAGE_IMAGES_CHANNEL]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      channelName: payload,
    },
  }),
  [UI.MANAGE_IMAGES_IMAGES]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      images: payload,
    },
  }),
  [UI.MANAGE_IMAGES_ORIGINAL_SORT]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      initialSort: payload,
    },
  }),
  [UI.MANAGE_IMAGES_NEW_SORT]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      idsSorted: payload,
    },
  }),
  [UI.MANAGE_IMAGES_DRAG]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      drag: {
        ...state.manageImages.drag,
        itemId: payload,
      },
    },
  }),
  [UI.MANAGE_IMAGES_CHANGE_SORT]: (state, { payload }) => {
    let items = [
      ...state.manageImages.idsSorted.filter(
        (id) => id !== state.manageImages.drag.itemId
      ),
    ];
    items.splice(payload, 0, state.manageImages.drag.itemId);
    return {
      ...state,
      manageImages: {
        ...state.manageImages,
        idsSorted: [...items],
      },
    };
  },
  [UI.MANAGE_IMAGES_SELECTED_IMAGE]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      selectedImage: payload,
    },
  }),
  [UI.MANAGE_IMAGES_CHANNEL]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      channelName: payload,
    },
  }),
  [UI.MANAGE_IMAGES_RESET]: (state) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      drag: {
        itemId: "",
      },
      toolbar: {},
      selection: [],
      idsSorted: state.manageImages.initialSort,
    },
  }),
  [UI.MANAGE_IMAGES_ORIENTATION]: (state, { payload }) => ({
    ...state,
    manageImages: {
      ...state.manageImages,
      orientation: payload
    }
  })
};
export default manageImages;
