import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, Redirect, useLocation, matchPath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Products from "views/Products";
import { DetailsPhoto, DetailsCopy, DetailsPublish, DetailsTask } from "components/organism";
import { DetailsTemplate, FilterLayout, ProductCanvasEditor } from "components/templates";
import { changeToolbar, closeLoader, closePanelLeft, openPanelLeft } from "_redux/ui/ui.actions";
import selectUiStore from "_redux/ui/ui.selector";
import { useAppSettings, useProduct } from "_hooks";
import { OWNER } from "_constants/catalog.constants";
import useFilterPanel from "_hooks/useFilterPanel";

const ProductRoutes = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const location = useLocation() ;
  const { canvasEditor } = useProduct();
  const { filterPanel, filterToggle } = useFilterPanel();
  const [activeSection, setActiveSection] = useState();
  const classForProducts = classNames("hide-transition", {
    "hide-transition__is-hidden": activeSection !== "grid"
  });
  const classForDetails = classNames("hide-transition", {
    "hide-transition__is-hidden": activeSection !== "details"
  });

  const { appColumnKeyProps } = useAppSettings();
  const { loader } = useSelector(selectUiStore);

  const pathnameHandler = () => {
    let section;
    if (matchPath(location.pathname, {
      path: `${path}/catalog/:catalogId`,
      strict: true
    })) {
      section = "grid";
      dispatch(
        openPanelLeft({
          typePanel: "catalog",
          panelProps: {
            area: "My catalogs",
            withSelection: false,
            type: OWNER
          }
        })
      );
      if (loader?.isOpen && loader?.typeLoader === "detailsPhoto"){
        dispatch(closeLoader());
      }
    }
    
    if (!section && matchPath(location.pathname, {
        path: `${path}/:key`,
        strict: true
      })) {
        if (filterPanel?.isOpen) filterToggle();
        section = "details";
        dispatch(changeToolbar("details"));
        dispatch(closePanelLeft());
      }
    
    if (!section && matchPath(location.pathname, {
        path: `${path}`,
        strict: true
      })) {
        if (loader?.isOpen && loader?.typeLoader === "detailsPhoto"){
          dispatch(closeLoader());
        }
        dispatch(changeToolbar("products"));
        section = "grid";
      }

    setActiveSection(section);
  };

  useEffect(() => {
    pathnameHandler();
  }, [location.pathname]);

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path={[
          `${path}/:key/tasks/:taskId?`,
          `${path}/catalog/:catalogId?`,
          `${path}/:key`,
          `${path}`
        ]}>

          {canvasEditor?.visible && <ProductCanvasEditor />}

          <FilterLayout
            dropPaste={{
              collection: "folderId",
              field: "key",
              label: `Filter by ${appColumnKeyProps?.name?.toUpperCase()}`,
            }}
          >
            <Products 
              className={classForProducts}
              isVisible={activeSection === "grid"} />

            <div className={classForDetails} >
              <Switch>
                <Route path={[
                  `${path}/:key`,
                  `${path}/:key/photos`,
                  `${path}/:key/publish`,
                  `${path}/:key/copy`,
                  `${path}/:key/task`
                  ]} exact>
                  <DetailsTemplate   
                    init={activeSection === "details"}
                  >
                    {activeSection === "details" &&
                      <Switch>
                        <Route path={`${path}/:key/photos`}>
                          <DetailsPhoto />
                        </Route>

                        <Route path={`${path}/:key/copy`}>
                          <DetailsCopy />
                        </Route>

                        <Route path={`${path}/:key/publish`}>
                          <DetailsPublish />
                        </Route>

                        <Route path={`${path}/:key/task`}>
                          <DetailsTask />
                        </Route>
                      </Switch>
                      }
                  </DetailsTemplate>
                </Route>
              </Switch>
            </div>
          </FilterLayout>
        </Route>

        <Redirect to={`${path}/`} />
      </Switch>
    </Suspense>
  );
};

export default ProductRoutes;
