import { GridLayout } from "components/templates/index";
import MultivendeSettingsCard from "module/Multivende/components/SettingsCard";

const Plugins = () => {
  return (
    <div className="v-plugins">
      <GridLayout settingsModules>
        <MultivendeSettingsCard />
      </GridLayout>
    </div>
  );
};

export default Plugins;
