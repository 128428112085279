import PropTypes from "prop-types";
import classNames from "classnames";
import { FormLabel } from "components/molecules/Form/index";

const prefix = "t-form-control";

const FormControl = ({ className, content, labelProps, orientation, ...props }) => {
  const classForComponent = classNames(prefix, {
    [className]: className,
    [`--${orientation}`]: orientation
  });

  return (
    <div className={classForComponent} {...props}>
      {labelProps?.content && <FormLabel {...labelProps} />}
      <div className={`${prefix}__input-control`}>
        {content}
      </div>
    </div>
  );
};

FormControl.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])
};

FormControl.defaultProps = {
  content: null,
  orientation: "horizontal",
  size: "xs"
};

export default FormControl;
