import { reduxActions as PRODUCT } from "_constants/product.constants";

const gallery = {
  [PRODUCT.IMAGES_FOR_GALLERY]: (state) => ({
    ...state,
    images: []
  })
};

export default gallery;
