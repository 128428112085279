import { reduxActions as UI } from "_constants/ui.constants";

export const setChannel = (channel) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_CHANNEL, payload: channel });

export const setInitialSort = (ids) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_ORIGINAL_SORT, payload: ids });

export const setNewSort = (ids) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_NEW_SORT, payload: ids });

export const setImages =
  (images = []) =>
  (dispatch) => {
    let arrayIds = images.map((image) => image.id);
    let count = { landscape: 0, portrait: 0, square: 0 };
    let higher = Object.keys(count).reduce((a, b) =>
      count[a] > count[b] ? a : b
    );
  
    dispatch({ type: UI.MANAGE_IMAGES_IMAGES, payload: images });
    dispatch({ type: UI.MANAGE_IMAGES_ORIENTATION, payload: higher });
    dispatch(setInitialSort(arrayIds));
    dispatch(setNewSort(arrayIds));
    dispatch(setImageOpen(images[0]));
  };

export const changeSort = (index) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_CHANGE_SORT, payload: index });

export const selectDragId = (id) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_DRAG, payload: id });

export const setImageOpen = (image) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_SELECTED_IMAGE, payload: image });

export const openManageImages = () => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_OPEN });

export const manageImagesReset = () => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_RESET });

export const closeManageImages = () => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_CLOSE });

export const setChannelName = (name) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_CHANNEL, payload: name });

export const addManageImagesSelection = (id) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_ADD_SELECTION, payload: id });

export const removeManageImagesSelection = (id) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_REMOVE_SELECTION, payload: id });

export const deleteManageImages = (ids) => (dispatch) =>
  dispatch({ type: UI.MANAGE_IMAGES_DELETE, payload: ids });
