import { IDLE } from "_constants/redux.constants";

const initialMultivendeState = {
  authorize: {
    data: null,
    error: null,
    status: IDLE
  },

  config: {
    data: null,
    error: null,
    status: IDLE
  },

  post: {
    data: null,
    error: null,
    status: IDLE
  },

  publish: {
    data: null,
    error: null,
    status: IDLE
  },

  sync: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialMultivendeState;
