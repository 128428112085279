import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NubeskIcons } from "components/atoms/index";
import { DropDown } from "components/templates";
import { BorderInputs } from "components/templates/index";
import classNames from "classnames";

const prefix = "m-select";

const Select = ({
  value,
  onChange,
  name,
  color,
  disabled,
  divisors,
  emptyLabel,
  fullwidth,
  options = [],
  orientation,
  readOnly,
  sm,
  xs,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  const [_value, setValue] = useState(value);
  const classForComponent = classNames(prefix, {
    [`${prefix}--${color}`]: color,
    "is-disabled": disabled,
    "is-sm": sm,
    "read-only": readOnly,
    [`${prefix}--${orientation}`]: orientation,
    "fullwidth": fullwidth
  });

  useEffect(() => {
    if (!value && !emptyLabel) {
      setValue(options[0]);
    } else {
      setValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (!clicked) return;

    if (typeof onChange === "function" && _value !== value) {
      if (typeof _value === "object") {
        onChange(_value);
      } else {
        onChange({ target: { value: _value, name } });
      }
    }
  }, [_value]);

  return (
    <div className={classForComponent} {...props}>
      <BorderInputs autoHeight bordered={false} disabled={disabled} readOnly={readOnly}>
        <DropDown
          divisors={divisors}
          copy
          options={[
            ...options?.map((data) => {
              if (typeof data === "object" && data?.value !== undefined && data?.label) {
                return {                  
                  onClick: () => {
                    setClicked(true);
                    setValue(data || {})
                  },
                  content: data?.label || "",
                  disabled: !!data?.disabled,
                  labelInfo: (data.labelInfo ? 
                    <p className={`${prefix}__label-info`}>{data.labelInfo}</p> 
                  : 
                    undefined)
                }
              } else {
                return {
                  onClick: () => {
                    setClicked(true);
                    setValue(data)
                  },
                  content: data
                }
              }
            }) ?? []
          ]}
          sm={!!sm}
          xs={!!xs}
          buttonProps={{
            content: (
              emptyLabel && _value === undefined ? 
                emptyLabel 
              : 
                (_value?.label ?? _value)
            ),
            type: "select",
            rightIcon: <NubeskIcons icon="down" />,
            className: `${prefix}__button`
          }}
        />
      </BorderInputs>
    </div>
  );
};

Select.propTypes = {
  fullwidth: PropTypes.bool
}

Select.defaultProps = {
  fullwidth: false
};

export default Select;
