import React from "react";

import {
  AiFillMinusCircle,
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineCheck,
  AiOutlineCloseCircle,
  AiOutlineClockCircle,
  AiOutlineCopyrightCircle,
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineDownload,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineInfoCircle,
  AiOutlineMail,
  AiOutlineMessage,
  AiOutlineLeft,
  AiOutlineLoading,
  AiOutlineLock,
  AiOutlinePushpin,
  AiOutlineRedo,
  AiOutlineRight,
  AiOutlineShareAlt,
  AiOutlineUndo,
  AiOutlineUser,
  AiOutlineWarning,
  AiOutlineZoomIn,
  AiOutlineZoomOut
} from "react-icons/ai";
import { BiCube, BiSliderAlt, BiDotsVerticalRounded, BiColorFill } from "react-icons/bi";
import {
  BsAspectRatio,
  BsChatRightDots,
  BsFiles,
  BsFullscreen, 
  BsFullscreenExit,
  BsGrid1X2Fill,
  BsLayersHalf,
  BsLink45Deg,
  BsPlusSquare, 
  BsPaperclip
} from "react-icons/bs";
import { CgMoreR, CgFormatText } from "react-icons/cg";
import { FaListUl, FaRobot, FaSlash } from "react-icons/fa";
import { GrDocumentText, GrDocumentUpload } from "react-icons/gr";
import {
  HiOutlineDocumentText,
  HiOutlineViewGrid,
  HiDocumentDownload,
  HiOutlineRefresh
} from "react-icons/hi";
import {
  IoChatbubbleEllipsesOutline,
  IoHelpCircleOutline,
  IoImageOutline,
  IoLogOutOutline,
  IoNotificationsOutline,
  IoSettingsOutline,
  IoCloudUploadOutline,
  IoPricetagsOutline,
  IoArchiveOutline,
} from "react-icons/io5";
import { FaGlobeAmericas, FaTasks, FaUsers } from "react-icons/fa";
import { FiAlertCircle, FiSend } from "react-icons/fi";

import { IoIosArrowDown, IoIosArrowUp,IoMdInfinite } from "react-icons/io";
import { PiSelectionInverseDuotone } from "react-icons/pi"
import { RiArrowLeftSLine, RiArrowRightSLine, RiFileExcel2Line, RiScissorsCutFill, RiSearch2Line } from "react-icons/ri";
import { SiGooglecloud } from "react-icons/si";
import { TiTimes } from "react-icons/ti";
import { GiResize } from "react-icons/gi";
import { ImSpinner9, ImEyedropper } from "react-icons/im";
import { LuGroup, LuImagePlus, LuUngroup, LuRefreshCcwDot } from "react-icons/lu";
import { MdOutlineBrandingWatermark, MdRestore, MdStore, MdOutlineSendTimeExtension } from "react-icons/md";
import { TbPlugConnectedX, TbRulerMeasure } from "react-icons/tb"
import { ReactComponent as Doc2 } from "_utils/icons/doc.svg";
import { ReactComponent as Pencil } from "_utils/icons/pencil.svg";
import { ReactComponent as Lens } from "_utils/icons/lens.svg";

const NubeskIcons = ({ icon, className, size, ...props }) => {
  const iconMap = {
    alert: FiAlertCircle,
    archive: IoArchiveOutline,
    arrowLeft: RiArrowLeftSLine,
    arrowRight: RiArrowRightSLine,
    calendar: AiOutlineCalendar,
    catalogs: HiOutlineDocumentText,
    bgColor: BiColorFill,
    refresh: HiOutlineRefresh,
    refreshDot: LuRefreshCcwDot,
    dimensions: BsAspectRatio,
    chat: BsChatRightDots,
    check: AiOutlineCheckCircle,
    circleT: AiOutlineCloseCircle,
    clip: BsPaperclip,
    clock: AiOutlineClockCircle,
    close: AiOutlineClose,
    cloud: SiGooglecloud,
    cube: BiCube,
    cut: RiScissorsCutFill,
    copy: AiOutlineInfoCircle,
    delete: AiOutlineDelete,
    disconnected: TbPlugConnectedX,
    doc: GrDocumentText,
    doc2: Doc2,
    dots: BiDotsVerticalRounded,
    down: IoIosArrowDown,
    download: HiDocumentDownload,
    downloadLine: AiOutlineDownload,
    dropper: ImEyedropper,
    edit: AiOutlineEdit,
    email: AiOutlineMail,
    eye: AiOutlineEye,
    excel: RiFileExcel2Line,
    files: BsFiles,
    filter: BiSliderAlt,
    full: BsFullscreen,
    fullExit: BsFullscreenExit,
    globe: FaGlobeAmericas,
    grid: HiOutlineViewGrid,
    group: LuGroup,
    help: IoHelpCircleOutline,
    hero: BsGrid1X2Fill,
    imagePlus: LuImagePlus,
    infinite: IoMdInfinite,
    inverseDuotone: PiSelectionInverseDuotone,
    layer: BsLayersHalf,
    left: AiOutlineLeft,
    lens: Lens,
    linkInclined: BsLink45Deg,
    list: FaListUl,
    lock: AiOutlineLock,
    logout: IoLogOutOutline,
    loading: AiOutlineLoading,
    message: AiOutlineMessage,
    measure: TbRulerMeasure,
    minus: AiFillMinusCircle,
    moduleSend: MdOutlineSendTimeExtension,
    notification: IoNotificationsOutline,
    outlineCheck: AiOutlineCheck,
    outlinePin: AiOutlinePushpin,
    pencil: Pencil,
    photos: IoImageOutline,
    plus: BsPlusSquare,
    products: CgMoreR,
    products2: IoPricetagsOutline,
    redo: AiOutlineRedo,
    resize: GiResize,
    restore: MdRestore,
    right: AiOutlineRight,
    rights: AiOutlineCopyrightCircle,
    robot: FaRobot,
    search: RiSearch2Line,
    send: FiSend,
    settings: IoSettingsOutline,
    share: AiOutlineShareAlt,
    slash: FaSlash,
    spin: ImSpinner9,
    store: MdStore,
    tasks: IoChatbubbleEllipsesOutline,
    tasks2: FaTasks,
    text: CgFormatText,
    times: TiTimes,
    undo: AiOutlineUndo,
    ungroup: LuUngroup,
    up: IoIosArrowUp,
    upload: IoCloudUploadOutline,
    user: AiOutlineUser,
    users: FaUsers,
    verticalMenu: BiDotsVerticalRounded,
    warning: AiOutlineWarning,
    watermark: MdOutlineBrandingWatermark,
    zoomIn: AiOutlineZoomIn,
    zoomOut: AiOutlineZoomOut,
  };
  if (!icon || !iconMap[icon]) return false;
  if (typeof iconMap[icon] !== undefined) {
    const Icon = iconMap[icon];
    return <Icon style={{ fontSize: size }} className={className} {...props} />;
  }
};

export default NubeskIcons;
