export const reduxActions = {
  AI_ANALYSIS_STARTED: "product/ai_analysis_started",
  AI_ANALYSIS_FAIL: "product/ai_analysis_fail",
  AI_ANALYSIS_SUCCESS: "product/ai_analysis_success",
  AI_ANALYSIS_RESET: "product/ai_analysis_reset",

  ALL_STARTED: "product/all_started",
  ALL_FAIL: "product/all_fail",
  ALL_SUCCESS: "product/all_success",
  ALL_REFRESH: "product/all_refresh",
  ALL_RESET: "product/all_reset",
  
  ALL_LIST_STARTED: "product/all_list_started",
  ALL_LIST_FAIL: "product/all_list_fail",
  ALL_LIST_SUCCESS: "product/all_list_success",
  ALL_LIST_RESET: "product/all_list_reset",

  ANALYZE_STARTED: "product/analyze_started",
  ANALYZE_FAIL: "product/analyze_fail",
  ANALYZE_SUCCESS: "product/analyze_success",
  ANALYZE_RESET: "product/analyze_reset",

  ANALYZE_ID_STARTED: "product/analyze_id_started",
  ANALYZE_ID_FAIL: "product/analyze_id_fail",
  ANALYZE_ID_SUCCESS: "product/analyze_id_success",
  ANALYZE_ID_RESET: "product/analyze_id_reset",

  COUNT_STARTED: "product/count_started",
  COUNT_FAIL: "product/count_fail",
  COUNT_SUCCESS: "product/count_success",
  COUNT_RESET: "product/count_reset",

  COUNTPIECE_STARTED: "product/countpiece_started",
  COUNTPIECE_FAIL: "product/countpiece_fail",
  COUNTPIECE_SUCCESS: "product/countpiece_success",
  COUNTPIECE_RESET: "product/countpiece_reset",
  COUNTPIECE_PAIR: "product/countpiece_pair",

  COUNT_BY_FIELD_STARTED: "product/count_by_field_started",
  COUNT_BY_FIELD_FAIL: "product/count_by_field_fail",
  COUNT_BY_FIELD_SUCCESS: "product/count_by_field_success",
  COUNT_BY_FIELD_RESET: "product/count_by_field_reset",

  DETAILS_STARTED: "product/details_started",
  DETAILS_FAIL: "product/details_fail",
  DETAILS_SUCCESS: "product/details_success",
  DETAILS_REFRESH: "product/details_refresh",
  DETAILS_RESET: "product/details_reset",

  DETAILS_RAIL_STARTED: "product/details_rail_started",
  DETAILS_RAIL_FAIL: "product/details_rail_fail",
  DETAILS_RAIL_SUCCESS: "product/details_rail_success",
  DETAILS_RAIL_RESET: "product/details_rail_reset",

  DOWNLOAD_XLS_STARTED: "product/download_xls_started",
  DOWNLOAD_XLS_FAIL: "product/download_xls_fail",
  DOWNLOAD_XLS_SUCCESS: "product/download_xls_success",
  DOWNLOAD_XLS_RESET: "product/download_xls_reset",

  CANVAS_EDITOR_TOGGLE: "product/canvas_editor_toggle",
  CANVAS_EDITOR_DATA: "product/canvas_editor_data",

  GET_STARTED: "product/get_started",
  GET_FAIL: "product/get_fail",
  GET_SUCCESS: "product/get_success",
  GET_RESET: "product/get_reset",

  IMAGES_FOR_GALLERY: "product/images_for_gallery",

  LAYOUT_FAIL: "product/all_fail",
  LAYOUT_RESET: "product/all_reset",
  LAYOUT_SUCCESS: "product/all_success",
  LAYOUT_STARTED: "product/all_started",

  LAYOUT_SAVE_FAIL: "product/layout_save_fail",
  LAYOUT_SAVE_RESET: "product/layout_save_reset",
  LAYOUT_SAVE_SUCCESS: "product/layout_save_success",
  LAYOUT_SAVE_STARTED: "product/layout_save_started",

  ONE_STARTED: "product/one_started",
  ONE_FAIL: "product/one_fail",
  ONE_SUCCESS: "product/one_success",
  ONE_REFRESH: "product/one_refresh",
  ONE_RESET: "product/one_reset",

  PUT_STARTED: "product/put_started",
  PUT_FAIL: "product/put_fail",
  PUT_SUCCESS: "product/put_success",
  PUT_RESET: "product/put_reset",
  
  PUT_COPY_STARTED: "product/put_copy_started",
  PUT_COPY_FAIL: "product/put_copy_fail",
  PUT_COPY_SUCCESS: "product/put_copy_success",

  POST_STARTED: "product/post_started",
  POST_FAIL: "product/post_fail",
  POST_SUCCESS: "product/post_success",
  POST_RESET: "product/post_reset",

  REMOVE_STARTED: "products/remove_started",
  REMOVE_FAIL: "products/remove_fail",
  REMOVE_SUCCESS: "products/remove_success",
  REMOVE_RESET: "products/remove_reset",

  SET_COUNTERS: "product/set_counters",

  UPDATE_FILES: "product/update_files"
};

export const TYPE_FILES = "files";
export const TYPE_FOLDER = "folder";

export const ACTIVE = "active";
export const INACTIVE = "inactive";

export const REQUIRED = "required";
export const TOREVIEW = "toReview";
export const APPROVED = "approved";
export const REJECTED = "rejected";
export const WAITINGPHOTOS = "waitingPhotos";
