import PropTypes from "prop-types";
import classNames from "classnames";

const prefix = "a-input";

const Input = ({
  autoComplete = false,
  className,
  disabled = false,
  name = "",
  onChange = () => {},
  placeholder = "",
  readOnly = false,
  size = "xs",
  value = "",
  ...props
}) => {
  const classForComponent = classNames(prefix, {
    [className]: className,
    [`--${size}`]: size
  });

  return (
    <input 
      className={classForComponent}
      disabled={disabled}
      name={name}
      onChange={(e) => onChange({ e, value: e?.target?.value })}
      value={value}
      placeholder={placeholder}
      readOnly={readOnly}
      {...props}
    />
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

export default Input;
