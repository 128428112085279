import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useMultivende from "../useMultivende";
import { Button, CircleStatus, NubeskIcons } from "components/atoms";
import { useAdaptation, useDetailsPhoto, useModal } from "_hooks";
import { APPROVED, TOREVIEW } from "_constants/product.constants";
import {
  disconectedComponent,
  emptyChannelConfigComponent,
  masterNoApprovedComponent,
  mvPendingComponent,
  mvPushedComponent,
  noMastersComponent,
  noSyncComponent
} from "./helpers";
import { LOADING } from "_constants/redux.constants";

const prefix = "mod-multivende-publish-rows";

const PublishRows = ({
  product
}) => {
  const { generateAdaptation } = useAdaptation();
  const history = useHistory();
  const {
    currentAdaptation,
    getMasterFiles,
    setImagesForGallery,
    setShowImages,
    updateProp
  } = useDetailsPhoto();
  const { closeModal, openModal } = useModal();
  const {
    configData,
    configStatus,
    fetchConfig: fetchConfigMv,
    postStatus,
    publish,
    publishStatus,
    syncOneProductMv,
    SYNC_STATUS_PENDING,
    SYNC_STATUS_PUSHED
  } = useMultivende();

  const initialized = useRef();
  const [bussy, setBussy] = useState(false);
  const [hasMasters, setHasMasters] = useState();
  
  const publishHandler = async({ folderId, multivendeMarketplaceId }) => {
    try {
      setBussy(multivendeMarketplaceId);
      let { data } = await publish({
        folderId: product?._id,
        multivendeMarketplaceId
      });
      updateProp({ _id: folderId, data: { plugins: data?.[0]?.plugins }});
    } catch(e) {}
    finally {
      setBussy(false);
    }
  };

  const clickHandler = useCallback((e, { adaptationId, imagesSet }) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (adaptationId) {
        if (imagesSet) {
          setShowImages({
            adaptationId,
          });
          let img = imagesSet.map((i) => ({
            index: i.index,
            id: `${adaptationId}_${i.index}`,
            naming: i.namefile,
            carousel: i.url,
            thumbnail: i.url,
            orientation: i.orientation,
            original: i.url,
          }));
          ;
          setImagesForGallery(img);
        } else {
          setShowImages(adaptationId);
        }
      }
    } catch(e) {}
  }, [setShowImages]);

  const createMV = async (e) => {
    e.stopPropagation();
    try {
      setBussy(true);
      let { data } = await syncOneProductMv({ folderId: product?._id });
      updateProp({ _id: product._id, data: { plugins: data[0].plugins }});
    } catch(e) {}
    finally {
      setBussy(false);
    }
  };

  const generatePublish = (e, { _name, adaptationId, marketplaceId  }) => {
    e.stopPropagation();
    openModal({
      closeBackdrop: false,
      header: { text: "Generar y publicar" },
      typeModal: "adaptations",
      modalProps: {
        fired: [],
        autoGenerate: {
          adaptationId,
          product: { data: product?.data }
        },
        productId: product?._id,
        isApproved: product?.process?.reviewMaster === APPROVED,
        onAdd: async () => {
          try {
            setBussy(marketplaceId);
            await generateAdaptation({ adaptationId, productId: product?._id });
            let { data } = await publish({
              folderId: product?._id,
              multivendeMarketplaceId: marketplaceId
            }, {
              feedback: {
                error: true,
                success: {
                  area: "Publicar",
                  info: `Imágenes publicadas en multivende para la cuenta ${_name}`
                }
              }
            });
            updateProp({ _id: product._id, data: { plugins: data[0].plugins }});
          } catch(e) {}
          finally {
            setBussy(false);
            closeModal();
          }
        }
      }
    });
  }

  const init = useCallback(() => {
    if (!initialized?.current) {
      initialized.current = true;
      fetchConfigMv();
    }
  }, [fetchConfigMv]);
  
  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setHasMasters(!!(getMasterFiles(product?.adaptationMastersId, product?.filesId)).length);
  }, [product]);
  
  if (configStatus === LOADING) return null;

  return (<div className={prefix} onClick={clickHandler}>
    {/* Sincronized */}
    {configData?.data?.connect?.status && 
    product?.plugins?.["plugin-multivende"] && 
    configData?.rules?.match &&
      configData?.rules?.match?.map(({ config, multivendeMarketplace, nubeskAdaptation }) => {
        let imgStatus = product?.process?.reviewMaster;
        let countFiles = 0;
        let status;
        let withChanges;
        let plugin = product.plugins["plugin-multivende"] ?? {};
        let marketPlaces = plugin?.multivendeMarketplaces;
        let imagesSet = [];
        
        if (!nubeskAdaptation || config?.syncImages !== true) return null;
        
        for (let f of product?.filesId) {
          if (f?.adaptationId === nubeskAdaptation) {
            countFiles += 1;
            let aux = marketPlaces?.[multivendeMarketplace._id]?.snapshot?.pushedAt; 
            if (moment(f.updatedAt).isAfter(moment(aux))) {
              withChanges = true;
            }
          }
        }

        if (marketPlaces?.[multivendeMarketplace._id]?.snapshot?.images) {
          let images = marketPlaces[multivendeMarketplace._id].snapshot.images;
          countFiles = images.length;
          imagesSet = images.sort((a, b) => {
            if (a.index < b.index) return 1;
            if (a.index > b.index) return -1;
            return 0;
          });
        }
        
        switch (marketPlaces?.[multivendeMarketplace._id]?.status) {
          case "pushed":
            if (!withChanges) status = "complete";
            else status = "incomplete";
            break;
          case "pending":
          default:
            break;
        }

        return (
          <div 
            className={`--row 
              ${currentAdaptation === nubeskAdaptation || currentAdaptation?.adaptationId === nubeskAdaptation
                ? "--selected" : ""
              }`
            }
            key={multivendeMarketplace?._id}
            onClick={(e) => clickHandler(e, { adaptationId: nubeskAdaptation, imagesSet })}
          >
            <div><CircleStatus status={status} />{multivendeMarketplace?._name}</div>
            <div><NubeskIcons icon="photos" size="2rem" />({countFiles})</div>
            <div>
              {!hasMasters && noMastersComponent}
              {hasMasters && imgStatus === TOREVIEW && masterNoApprovedComponent}
              {imgStatus === APPROVED && 
              (marketPlaces?.[multivendeMarketplace?._id]?.status === SYNC_STATUS_PENDING ||
                marketPlaces?.[multivendeMarketplace?._id]?.status === undefined) && 
                mvPendingComponent({
                  _name: multivendeMarketplace?._name,
                  adaptationId: nubeskAdaptation,
                  bussy,
                  countFiles,
                  generatePublish,
                  marketplaceId: multivendeMarketplace?._id,
                  product,
                  publishHandler,
                  publishStatus
                })
              }

              {imgStatus === APPROVED && 
                marketPlaces?.[multivendeMarketplace?._id]?.status === SYNC_STATUS_PUSHED && 
                mvPushedComponent({
                  countFiles,
                  bussy,
                  marketplaceId: multivendeMarketplace?._id,
                  plugin,
                  product,
                  publishHandler,
                  publishStatus,
                  withChanges
                })
              }
            </div>
            <div>
              <Button
                content={<NubeskIcons icon="dots" />}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        );
      })
    }

    {/* Empty channels config */}
    {configData?.data && configData.rules &&
    configData.data?.connect?.status && 
    configData.rules?.match && 
    !configData.rules.match.filter(
      (a) => !!a?.nubeskAdaptation && a?.config?.syncImages === true
    )?.[0] &&
      emptyChannelConfigComponent({ history })
    }
    
    {/*Not synchronized */}
    {configData?.data?.connect?.status && 
    !product?.plugins?.["plugin-multivende"]?.multivendeProductId	 &&
      noSyncComponent({ createMV, postStatus })
    }

    {/* Disconnected*/}
    {(!configData || !configData?.data?.connect?.status) &&
      disconectedComponent({ history })
    }
  </div>);
};

export default PublishRows;
