import initialMultivendeState from "../multivende.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as MULTIVENDE } from '../../multivende.constants';

const config = {
  [MULTIVENDE.CONFIG_STARTED]: (state) => ({
    ...state,
    config: {
      ...state.config,
      status: LOADING
    }
  }),
    
  [MULTIVENDE.CONFIG_FAIL]: (state, { payload }) => ({
    ...state,
    config: {
      ...state.config,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [MULTIVENDE.CONFIG_SUCCESS]: (state, { payload }) => ({
    ...state,
    config: {
      ...state.config,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),
  [MULTIVENDE.CONFIG_RESET]: (state) => ({
    ...state,
    config: initialMultivendeState.config
  })
};

export default config;
