import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useChannelLayout, useAdaptation } from "_hooks";
import useMultivende from "../useMultivende";
import { FlexComponent, FormControl } from "components/templates";
import { Card } from "components/organism";
import { HelpInfo } from "components/molecules";
import FormInput from "components/molecules/Form/FormInput";
import { Badge, Button, CircleStatus } from "components/atoms";
import ChannelTable from "./ChannelTable";
import { LOADING } from "_constants/redux.constants";

const prefix = "mod-multivende-settings-card";

const SettingsCard = ({
  headerClass
}) => {
  const {
    adaptations,
    fetchListAdaptations,
    getDropdownList
  } = useAdaptation();
  
  const { fetch: fetchLayouts, listData: layouts} = useChannelLayout();

  const {
    checkSaved,
    connectTo,
    configData,
    fetchConfig,
    logo,
    save,
    syncStatus,
    syncProductsMv
  } = useMultivende();

  const { t } = useTranslation();

  const initialized = useRef();
  const [edit, setEdit] = useState(false);
  const [channelOpts, setChannelOpts] = useState();
  const [code, setCode] = useState("");
  const [savedData, setSavedData] = useState();

  const classForHeader = classNames(`${prefix}__header`, {
    [headerClass]: headerClass
  });

  const changeHandler = (_idMv, data) => setSavedData((s) => {
    let newData = { ...s };
    if (!newData) newData = {};
    newData[_idMv] = {
      ...newData[_idMv],
      ...data
    };
    return newData;
  });

  const calcDays = () => {
    let start = moment();
    let days = start.diff(moment(configData?.data?.connect?.liveSince), "days") + 1;
    
    if (days > 365) {
      let years = parseInt(days / 365);
      days = `${years}a ${days%365}`;
    }
    return `${days}d`;
  };

  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      fetchConfig();
      fetchListAdaptations();
      fetchLayouts();
    }
  }, []);

  useEffect(() => {
    if (adaptations && layouts) {
      setChannelOpts(getDropdownList(layouts, adaptations));
    }
  }, [adaptations, layouts]);


  useEffect(() => {
    setCode(configData?.data?.appClient?.authorizationToken || "");
    setSavedData(checkSaved(configData?.rules?.match));
  }, [configData]);

  return (
    <Card
      className={prefix}
      header={
        <FlexComponent className={classForHeader}>
          <div>
            <img className="--logo" src={logo} alt=""/>
            <div className="--name">Multivende</div>
          </div>
          <div>
            {edit ?
              <Button
                content={t("common:form.save")}
                onClick={() => {
                  save(savedData);
                  setEdit(false);
                }}
                sm
                type="success"
              />
            :
              <Button 
                content={t("module:multivende.settings.config")}
                onClick={() => setEdit(true)}
                sm
                type="underline"
              />
            }
            {edit ?
              <Button 
                content={t("common:form.cancel")}
                onClick={() => {
                  setEdit(false);
                  setCode(configData?.data?.appClient?.authorizationToken || "");
                }}
                sm
                type="underline"
              />
            :
              <Badge
                border="purple"
                content={<>
                  <CircleStatus status={configData?.data?.connect?.status ? "approved": "rejected"} />
                    {configData?.data?.connect?.status ? "ON" : "OFF"}
                  </>  
                }
              />
            }
          </div>
        </FlexComponent>
      }  
    >
      <div className={`${prefix}__content`}>
        <div className="--mod-config">
          <FormInput 
            disabled={!edit}
            label={<>Código de autorización<HelpInfo>Help</HelpInfo></>}
            onChange={({ value }) => setCode(value)}
            orientation="vertical"
            size="sm"
            value={code}
          />

          <FormControl
            content={
              !configData?.data?.connect?.status || configData?.data?.appClient?.authorizationToken !== code ? 
                <Button 
                  content="Conectar"
                  disabled={!edit}
                  onClick={async() => {
                    setEdit(false);
                    await connectTo({ code });
                    fetchConfig();
                  }}
                  sm
                  type="primary"
                  fullwidth
                />
              :
                calcDays()
            }
            labelProps={{
              content: configData?.data?.connect?.liveSince && configData?.data?.appClient?.authorizationToken === code? 
                <>Activo desde<HelpInfo>Help</HelpInfo></> 
              : 
                " "
              ,
              size: "sm"
            }}
            orientation="vertical"
          />
        </div>

        {(configData?.data?.connect?.status && !!configData?.rules?.match?.length) &&
          savedData && <> 
          <div className="--mod-sync">
            <div>
              <div>
                <div>Sincronizar productos</div>
                <Button
                  disabled={!edit || syncStatus === LOADING}
                  content="Ejecutar"
                  onClick={syncProductsMv}
                  type="primary"
                  sm
                />
              </div>
              <div>
                <div>Autosync</div>
                <Badge content="soon" border="purple" />
              </div>
            </div>

            <div>
              <div></div>
              <div></div>
            </div>
          </div>

          <div className="--mod-config-aditional">
            <ChannelTable 
              editable={edit}
              channels={configData?.rules?.match}
              channelOpts={channelOpts}
              savedData={savedData}
              onChanges={changeHandler}
            />
          </div>
        </>}
      </div>
    </Card>
  );
};

export default SettingsCard;
