import initialState from "../multivende.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as Multivende } from '../../multivende.constants';

const authorize = {
  [Multivende.AUTHORIZE_STARTED]: (state) => ({
    ...state,
    authorize: {
      ...state.authorize,
      status: LOADING
    }
  }),
    
  [Multivende.AUTHORIZE_FAIL]: (state, { payload }) => ({
    ...state,
    authorize: {
      ...state.authorize,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [Multivende.AUTHORIZE_SUCCESS]: (state, { payload }) => ({
    ...state,
    authorize: {
      ...state.authorize,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    },
    config: {
      ...state?.config ?? {},
      data: {
        ...state?.config?.data ?? {},
        data: {
          ...state?.config?.data?.data ?? {},
          connect: payload?.data?.[0]?.connect,
          token: payload?.data?.[0]?.token,
          appClient: payload?.data?.[0]?.appClient
        }
      }
    }
  }),
  [Multivende.AUTHORIZE_RESET]: (state) => ({
    ...state,
    authorize: initialState.authorize
  })
};

export default authorize;
