import { useTranslation } from "react-i18next";
import { HelpInfo } from "components/molecules";
import FormInput from "components/molecules/Form/FormInput";
import { Badge, Switch } from "components/atoms";
import { Select } from "components/molecules/Inputs";

const prefix = "mod-multivende-channel-table";

const ChannelTable = ({
  channels = [],
  channelOpts = [],
  editable = false,
  savedData,
  onChanges = () => {}
}) => {
  const { t } = useTranslation();

  return (
    <div className={prefix}>
      <header>
        <div>{t("module:multivende.settings.mvName")}<HelpInfo>Help</HelpInfo></div>
        <div>{t("module:multivende.settings.nbskName")}<HelpInfo>Help</HelpInfo></div>
        <div className="--config">
          <div>{t("module:multivende.settings.images")}<HelpInfo>Help</HelpInfo></div>
          <div>{t("module:multivende.settings.attributes")}<HelpInfo>Help</HelpInfo></div>
        </div>
      </header>
      <div className="--content">
        {channels?.map(({ multivendeMarketplace }) => {
          
          return (
            <div className="--row" key={multivendeMarketplace?._id}>
            	<div>
              	<FormInput
                	disabled={true}
                	orientation="vertical"
                	readOnly
                	size="sm"
                	value={multivendeMarketplace?._name}
              	/>
            	</div>
            	<div>
              	<Select
                	disabled={!editable}
                  emptyLabel={"Selecciona una adaptación"}
                	fullwidth
                	options={channelOpts}
                  onChange={(a) => onChanges(multivendeMarketplace?._id, { value: a?.value?._id, syncImages: true })}
                	size="sm"
                  value={channelOpts?.filter((e) => e?.value?._id === savedData?.[multivendeMarketplace?._id]?.value)?.[0]}
              	/>
            	</div>
            	<div className="--config">
              	<Switch
                	disabled={!editable}
                	checked={savedData?.[multivendeMarketplace?._id]?.syncImages}
                  onChange={(a) => {
                    onChanges(multivendeMarketplace?._id, { syncImages: a })
                  }}
              	/>
              	<Badge content="soon" border="purple" />
            	</div>
          	</div>)
          })}
      </div>
    </div>
  );
};

export default ChannelTable;
