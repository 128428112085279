import { NubeskIcons } from "components/atoms/index";
import ReactTooltip from 'react-tooltip';

const prefix = "m-help-info";

const selector = "ih-" + Date.now();

const HelpInfo = ({
  children,
  placement = "top"
}) => {
  return(
    <div className={prefix}>
      <NubeskIcons icon="help" data-for={selector} data-tip size="1.8rem"/>
      
      <ReactTooltip
        id={selector}
        place={placement}
        effect="solid"
        backgroundColor={"var(--color-purple)"}
      >
        {children}
      </ReactTooltip>
    </div>
    
  )
};

export default HelpInfo;
