import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDragId,
  setImageOpen,
  changeSort,
  addManageImagesSelection,
  removeManageImagesSelection,
} from "_redux/ui/ui.actions";
import { selectDragItem } from "_redux/ui/ui.selector";
import classNames from "classnames";

const ImageCard = React.memo(({ i, id, image, active, ...props }) => {
  const prefix = "m-image-card";

  const dragItem = useSelector(selectDragItem);
  const dispatch = useDispatch();
  const onDragStart = (e) => {
    //Se almacena el elemento cuyo index coincida con el recibido
    dispatch(selectDragId(image.id));
    //Se configura el elemento que se arrastra
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    //Se crea un nodo vacio para que no se vea el elemento arrastrado
    let dragImgEl = document.createElement("span");
    dragImgEl.setAttribute( 
      "style",
      "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;"
    );
    document.body.appendChild(dragImgEl);
    e.dataTransfer.setDragImage(dragImgEl, 0, 0);
  };
  const onDragOver = () => {
    if (dragItem === image.id) return;

    dispatch(changeSort(i));
  };
  const classForComponent = classNames(prefix, {
    [`${prefix}--${image.orientation}`]: image?.orientation,
    [`${prefix}--dragging`]: dragItem === image?.id,
    [`${prefix}--active`]: active,
  });
  return (
    <div
      className={classForComponent}
      draggable={true}
      onDragStart={(e) => {
        onDragStart(e, i);
      }}
      onDragEnd={() => {
        dispatch(selectDragId(""));
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onClick={
        dragItem === image.id
          ? () => {}
          : () => {
              dispatch(setImageOpen(image));
              if (active)
                return dispatch(removeManageImagesSelection(image.id));
              dispatch(addManageImagesSelection(image.id));
            }
      }
      {...props}

      /* onClick={() => setActive(!active)} */
    >
      <img src={image.carousel} draggable={false} />
      <span
        onDragOver={() => {
          onDragOver(i);
        }}
      >
        {i + 1}
      </span>
    </div>
  );
});

export default ImageCard;
