export const reduxActions = {
  ADAPTATIONS_STARTED: "app/property_adaptations_started",
  ADAPTATIONS_FAIL: "app/property_adaptations_fail",
  ADAPTATIONS_SUCCESS: "app/property_adaptations_success",

  COLUMN_KEY_STARTED: "app/key_started",
  COLUMN_KEY_FAIL: "app/key_fail",
  COLUMN_KEY_SUCCESS: "app/key_success",

  COMPONENTDRAWING_SET: "app/component_drawing_set",
  COMPONENTDRAWING_STARTED: "app/component_drawing_started",
  COMPONENTDRAWING_FAIL: "app/component_drawing_fail",
  COMPONENTDRAWING_SUCCESS: "app/component_drawing_success",

  CONFIGURED: "app/configurated",
  CONFIG_GOT: "app/config_got",

  COPY_WORKFLOW_STARTED: "app/copy_workflow_started",
  COPY_WORKFLOW_FAIL: "app/copy_workflow_fail",
  COPY_WORKFLOW_SUCCESS: "app/copy_workflow_success",

  EXTERNAL_NUBAI_STARTED: "app/external_nubai_started",
  EXTERNAL_NUBAI_SET: "app/external_nubai_set",
  EXTERNAL_NUBAI_FAIL: "app/external_nubai_fail",
  EXTERNAL_NUBAI_SUCCESS: "app/external_nubai_success",

  FONTS_STARTED: "app/fonts_started",
  FONTS_FAIL: "app/fonts_fail",
  FONTS_SUCCESS: "app/fonts_success",
  FONTS_SET: "app/fonts_set",

  LAYERS_STARTED: "app/layers_started",
  LAYERS_FAIL: "app/layers_fail",
  LAYERS_SUCCESS: "app/layers_success",

  MEDIA_HELP_FAIL: "app/media_help_fail",
  MEDIA_HELP_SET: "app/media_help_set",
  MEDIA_HELP_STARTED: "app/media_help_started",
  MEDIA_HELP_SUCCESS: "app/media_help_success",

  MODULES_FAIL: "app/modules_fail",
  MODULES_SET: "app/modules_set",
  MODULES_STARTED: "app/modules_started",
  MODULES_SUCCESS: "app/modules_success",

  LOOK_AND_FEEL_SET: "app/look_and_feel_set",
  LOOK_AND_FEEL_STARTED: "app/look_and_feel_started",
  LOOK_AND_FEEL_FAIL: "app/look_and_feel_fail",
  LOOK_AND_FEEL_SUCCESS: "app/look_and_feel_success",

  POST_ADAPTATIONS_STARTED: "app/post_adaptations_started",
  POST_ADAPTATIONS_FAIL: "app/post_adaptations_fail",
  POST_ADAPTATIONS_SUCCESS: "app/post_adaptations_success",

  PUT_ADAPTATIONS_STARTED: "app/put_adaptations_started",
  PUT_ADAPTATIONS_FAIL: "app/put_adaptations_fail",
  PUT_ADAPTATIONS_SUCCESS: "app/put_adaptations_success",

  PUT_COLUMN_KEY_STARTED: "app/put_column_key_started",
  PUT_COLUMN_KEY_FAIL: "app/put_column_key_fail",
  PUT_COLUMN_KEY_SUCCESS: "app/put_column_key_success",

  PUT_PROPERTY_COLUMNS_STARTED: "app/put_property_column_started",
  PUT_PROPERTY_COLUMNS_FAIL: "app/put_property_column_fail",
  PUT_PROPERTY_COLUMNS_SUCCESS: "app/put_property_column_success",

  PROP_COLUMNS_STARTED: "app/property_columns_started",
  PROP_COLUMNS_FAIL: "app/property_columns_fail",
  PROP_COLUMNS_SUCCESS: "app/property_columns_success",

  POST_COLUMN_KEY_STARTED: "app/property_column_key_started",
  POST_COLUMN_KEY_FAIL: "app/property_column_key_fail",
  POST_COLUMN_KEY_SUCCESS: "app/property_column_key_success",

  POST_PROPERTY_COLUMNS_STARTED: "app/post_property_column_started",
  POST_PROPERTY_COLUMNS_FAIL: "app/post_property_column_fail",
  POST_PROPERTY_COLUMNS_SUCCESS: "app/post_property_column_success",

  GET_TEMPLATE_STARTED: "app/get_template_started",
  GET_TEMPLATE_FAIL: "app/get_template_fail",
  GET_TEMPLATE_SUCCESS: "app/get_template_success",
  GET_TEMPLATE_RESET: "app/get_template_reset",

  GET_IMAGERY_WORKFLOW_STARTED: "app/get_imagery_workflow_started",
  GET_IMAGERY_WORKFLOW_FAIL: "app/get_imagery_workflow_fail",
  GET_IMAGERY_WORKFLOW_SUCCESS: "app/get_imagery_workflow_success",

  UPDATE_PROP: "app/update_prop"
};

export const ADAPTATIONS_MODULE = "adaptations-module";
export const NUBAI_MODULE = "nubai-module";
export const BACKREMOVAL_MODULE = "backremoval-module";
export const BATCH_MODULE = "batchs-module";
export const LAYER_MODULE = "layers-module";
export const LAYOUTS_MODULE = "layoputs-module";
export const LINKS_MODULE = "links-module";
export const TASKS_MODULE = "task-module";
export const WATERMARK_MODULE = "watermark-module";

export const MULTIVENDE_PLUGIN = "multivende-plugin";

export const ACTIVE = "active";

export const FORK_CORE = "core";
