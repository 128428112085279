import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const BorderInputs = ({
  bordered = true,
  children,
  autoHeight,
  disabled,
  error,
  type
}) => {
  const [focus, setFocus] = useState(false);
  const classForComponent = classNames("t-border-inputs", {
    "is-focused": focus,
    "auto-height": autoHeight,
    "is-disabled": disabled,
    [`t-border-inputs--${type}`]: type,
    "has-error": error
  });

  return (
    <div className={classForComponent} style={bordered ? { "--border-width": "2px" } : {}}>
      <div
        data-testid={`q-border-inputs`}
        style={{ width: "100%", height: "100%" }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        {children}
      </div>
    </div>
  );
};

export default BorderInputs;

BorderInputs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
