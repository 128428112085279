import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useModal } from "_hooks";
import useSyncfonia from "module/Syncfonia/useSyncfonia";
import { Button } from "components/atoms";
import Controls from "./Controls";
import Processing from "./Processing";
import { toggleRefresh } from "_redux/ui/ui.actions";
import { TYPE_PROVIDER, TYPE_RETAILER } from "module/Syncfonia/syncfonia.constants";

const prefix = "mod-syncfonia-update-catalog-modal";

const UpdateCatalogModal = ({ type = TYPE_RETAILER }) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const [opt1, setOpt1] = useState(false);
  const [opt2, setOpt2] = useState(false);
  const [step, setStep] = useState(1);

  const { updateCatalogProvider, updateCatalogRetail } = useSyncfonia();

  const { t } = useTranslation();

  const element = () => {
    switch(step) {
      case 1:
        return <Controls 
          opt1={opt1}
          opt2={opt2}
          prefix={prefix}
          setOpt1={setOpt1}
          setOpt2={setOpt2}
          type={type}
        />
      case 2:
        return <Processing
          prefix={prefix}
        />
      default:
        return null;
    }
  };

  const sendRequest = () => {
    setStep(2);
    switch (type) {
      case TYPE_PROVIDER:
        updateCatalogProvider({
          opts: {
            getGtin: opt1,
            getAssets: opt2
          }
        })
        break;
      case TYPE_RETAILER:
      default:
        updateCatalogRetail({
          opts: {
            getAssets: opt1,
            getInfo: opt2
          }
        });
        break;
    }
  };

  return (
    <div className={prefix}>
      {element()}

      <div className={`${prefix}__buttons`}>
        <Button
          content={t("common:form.cancel")}
          type="underline"
          onClick={closeModal}
        />
        
        {step === 1 &&
          <Button
            content={t("common:words.continue")}
            disabled={!opt1 && !opt2}
            sm
            type="purple"
            onClick={sendRequest}
          />
        }

        {step === 2 &&
          <Button
            content={t("common:general.closeAndNotify")}
            sm
            type="purple"
            onClick={() => {
              closeModal();
              dispatch(toggleRefresh("products"));
            }}
          />
        }
      </div>
    </div>
  );
};

export default UpdateCatalogModal;
