import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DropDown, FlexTable } from "components/templates";
import { AvatarName, TaskProgress } from "components/molecules";
import {Avatar, FlexCell, NubeskIcons, TaskEta } from "components/atoms/";
import { useTasks, useTask } from "_hooks";
import { selectTaskAll } from "_redux/task/task.selector";
import { resetAll } from "_redux/task/task.actions";
import { FAILURE, SUCCESS } from "_constants/redux.constants";
import { openModal } from "_redux/ui/ui.actions";
import { ARCHIVED } from "_constants/task.constants";
import { useAppSettings } from "_hooks";

const prefix = "v-tasks";

const Tasks = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { appColumnKeyProps } = useAppSettings();
  const initialized = useRef();
  const { fetch } = useTasks();
  const { shareTask, update } = useTask();
  const { data: tasks, status: tasksStatus } = useSelector(selectTaskAll);

  const init = useCallback(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      init();
    }
  }, [init])

  return (
    <div className={prefix}>
      <FlexTable
        bodyClass={`${prefix}__table-body`}
        headerCol={9}
        rowCol={9}
        showEmpty={[SUCCESS, FAILURE].indexOf(tasksStatus) !== -1}
        gridTemplateColumns="150px 1fr 120px 1fr 150px 150px 1fr 120px  40px"
        emptyData={t("tasks:table.emptyData")}
        headers={
          <>
            <FlexCell className="u-uppercase">{t("tasks:table.headers.id")}</FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.headers.name")}</FlexCell>
            <FlexCell
              justify="center"
              className="u-uppercase"
            >{t("tasks:table.headers.creator")}</FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.headers.type")}</FlexCell>
            <FlexCell
              justify="center"
              className="u-uppercase"
            >{t("tasks:table.headers.items", { key: appColumnKeyProps?.name})}</FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.headers.progress")}</FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.headers.sharedWith")}</FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.headers.eta")}</FlexCell>
            <FlexCell></FlexCell>
          </>
        }
        rows={tasks && Array.isArray(tasks) && tasks?.map((r) => <>
          <FlexCell className="u-purple">
            <Link to={`/tasks/${r.key}`}>{r.key}</Link>
          </FlexCell>
          <FlexCell className="u-purple">
            <Link to={`/tasks/${r.key}`}>{r.name}</Link>
          </FlexCell>
          <FlexCell justify="center">
            <Avatar
              firstName={r?.userId?.account?.firstName || ""}
              lastName={r?.userId?.account?.lastName || ""}
              colorRange={[107, 112, 213]}
            />
          </FlexCell>
          <FlexCell>{t(`tasks:table.types.${r?.type?.toUpperCase()}`, { key: appColumnKeyProps?.name })}</FlexCell>
          <FlexCell justify="center">({r?.foldersId?.length})</FlexCell>
          <FlexCell align="stretch" justify="start">
            <TaskProgress
              height="30px"
              completed={r?.process?.percentProgress ? Math.round(r.process.percentProgress * 100) : 0}
              width="100%"
              placementLabel
            />
          </FlexCell>
          <FlexCell>
            {r?.share?.length ? 
              <>
                {r.share?.filter((r, i) => i < 3)?.map((s) => <Avatar
                  firstName={s.account.firstName}
                  lastName={s.account.lastName}
                  colorRange={[107, 112, 213]}
                />)}
                {r.share?.length > 3 && <Avatar
                  firstName="."
                  lastName="."
                  colorRange={[107, 112, 213]}
                />}
              </>
            :
              <AvatarName
                colorRange={[107, 112, 213]}
                letters={" "}
                emptyLabel={t("tasks:table.nobody")}
              />
            }
          </FlexCell>
          <FlexCell>
            <TaskEta date={r?.process?.deliveryDate} status={r.status} />
          </FlexCell>
          <FlexCell>
            <DropDown
              buttonProps={{
                content: <NubeskIcons icon="dots" />,
                type: "color"
              }}
              options={[{
                content: t("tasks:actions.share"),
                onClick: () => dispatch(openModal({
                  modalProps: {
                    buttons: {
                      share: t("tasks:actions.share")
                    },
                    share: r.share?.map((s) => s._id) ?? [],
                    onShare: (usersSelected) => 
                      shareTask(
                        r._id, 
                        usersSelected?.map((r) => r._id),
                        "taskAll"
                      )
                  },
                  typeModal: "share",
                  header: {
                    text: (<>
                      <NubeskIcons icon="share"  /> {t("tasks:actions.share")}
                    </>),
                    rightItem: r?.key,
                  }
                }))
              }, {
                content: t("tasks:actions.editTask"),
                onClick: () => dispatch(openModal({
                  modalProps: {
                    task: {
                      _id: r?._id,
                      data: r?.data,
                      process: r?.process || null,
                      name: r?.name,
                      type: r?.type
                    }
                  },
                  typeModal: "editTask",
                  header: {
                    text: (<>
                      <NubeskIcons icon="edit"  /> {t("tasks:actions.editTask")}
                    </>),
                    rightItem: r?.key,
                  }
                }))
              }, {
                content: t("tasks:actions.archiveTask"),
                onClick: () => update(r?._id, { status: ARCHIVED }, "taskAll")
              }]}
            />
          </FlexCell>
        </>)}
      />
    </div>
  );
};

export default Tasks;
