import React, { Fragment, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ColumnsTemplate } from "components/templates";
import { MagnifierImage, NubeskIcons } from "components/atoms";
import SimpleScroller from "../Scroller/SimpleScroller";
import{ selectManageImages, selectShortcuts, selectInitialSort} from "_redux/ui/ui.selector";

const prefix = "o-gallery";
let timer;

const Gallery = ({
  images = null,
  isNoBg,
  onChangeIndex = null,
  onRequestSwatch,
  orientation = "portrait",
  setMagnifierProps,
  magnifierProps,
}) => {
  let refScroll = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState();
  const shortcuts = useSelector(selectShortcuts);
  const [currentOrder, setCurrentOrder] = useState([]);
  const _images = useSelector(selectManageImages);
  const _arrayIds = useSelector(selectInitialSort);

  useEffect(()=>{
    if (!_arrayIds) return false;
    let ordered = _arrayIds.map(id => _images.find(image=> image.id === id));
    setCurrentOrder(ordered);
  },[_arrayIds])

  const classForComponent = classNames(prefix, {
    [`${prefix}--${orientation}`]: orientation,
    "is-loading": loading,
  });

  useEffect(() => {
    setLoading(true);
    timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    setCurrentIndex(0);
    setLoaded({});

  }, [images]);

  useEffect(() => {
    if (onChangeIndex) onChangeIndex(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (shortcuts?.photo === 1) {
      return setCurrentIndex((previousState) =>
        previousState === images?.length - 1 ? 0 : previousState + 1
      );
    }
    if (shortcuts?.photo === 0) {
      setCurrentIndex((previousState) =>
        !previousState ? images?.length - 1 : previousState - 1
      );
    }
  }, [shortcuts]);

  return (
    <div className={classForComponent}>
      <ColumnsTemplate
        leftWidth={`${90}px`}
        transition={"0s"}
        isHorizontal={false}
        leftComponent={
          <div className={`${prefix}__miniature`}>
            <SimpleScroller 
              isHorizontal={false}
              removeTrackYWhenNotUsed={true}
              permanentTrackY={false}
            >
              <div className={`${prefix}__miniature-images`} ref={refScroll}>
                {!loading && currentOrder?.map((src, index) => (
                  error[src] ? null : (
                    <Fragment key={index}>
                      <img 
                        alt=""
                        className="u-is-hidden" 
                        src={src?.carousel}
                        onError={() => setError({ ...error, [src]: src })}
                        onLoad={() => setLoaded((prevState) => ({
                        ...prevState ?? {},
                          [src.id]: true
                        }))}
                      />
                      {loaded?.[src.id] === true ? (
                        <div 
                          className={`${prefix}__miniature-container ${
                            currentIndex === index ? "is-active" : ""
                          }`}
                          onClick={() => setCurrentIndex(index)}
                        >
                        <img src={src?.carousel} alt="" />
                        </div>
                      ) : (
                        <div 
                          className={`${prefix}__miniature-container`}
                          onClick={() => setCurrentIndex(index)}
                        >
                          <NubeskIcons className="u-spin u-purple" icon="loading" />
                        </div>
                      )}
                    </Fragment>
                  )
                ))}
              </div>
            </SimpleScroller>
          </div>
        }
        rightComponent={
          <div className={`${prefix}__featured`}>
            {loading ? null : (
              <MagnifierImage
                orientationAll={""}
                setMagnifierProps={setMagnifierProps}
                magnifierProps={magnifierProps}
                onRequestSwatch={onRequestSwatch}
                thumbnail={currentOrder?.[currentIndex]?.thumbnail ?? false}
                src={currentOrder?.[currentIndex]?.original ?? false}
                isNoBg={isNoBg}
                id={_arrayIds?.[currentIndex]}
                previousImage={() =>
                  setCurrentIndex((previousState) =>
                    !previousState
                      ? currentOrder?.length - 1
                      : previousState - 1
                  )
                }
                nextImage={() =>
                  setCurrentIndex((previousState) =>
                    !previousState
                      ? currentOrder?.length - 1
                      : previousState - 1
                  )
                }
              />
            )}
          </div>
        }
      />
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
  onChangeIndex: PropTypes.func,
  orientation: PropTypes.oneOf(["portrait", "landscape", "square"])
};

export default Gallery;
