import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonColor } from "components/molecules";
import { InputTest,Select } from "components/molecules/Inputs";
import { Button, CheckBox, DimensionPreview, NubeskIcons } from "components/atoms";
import { useAdaptation, useAppSettings, useForm, useLayer, useUser } from "_hooks";
import {
  CHANGE_BG,
  CUSTOM,
  RADIAL,
  REDIMENSION,
  SOLID,
  STANDARD,
  TRANSPARENT,
  WITH_MARGINS,
} from "_constants/adaptation.constants";
import { $ADAPTATION_EDIT } from "_constants/permission.constants";
import { backgroundColor } from "_utils/background";
import { LOADING } from "_constants/redux.constants";
import { WATERMARK_MODULE } from "_constants/app.constants";
import { Card } from "../index";
import { FlexComponent } from "components/templates/index";

const prefix = "o-channel-adaptation-card";

const ChannelAdaptationsCard = ({ adaptation, layoutKey }) => {
  const {
    bgTypeOpts,
    deleteWatermark,
    formatOpts,
    foldingOpts,
    mimeTypeOpts,
    mastersData,
    openWmCanvasEditor,
    renameTypeOpts,
    separatorOpts,
    setWmCanvasEditor,
    typeOpts,
    updateAdaptation
  } = useAdaptation();
  const { appColumnKeyProps, appLookAndFeel } = useAppSettings();

  const [adaptationForm] = useState({});
  const { form, setForm, disabled, changeHandler } = useForm({
    schemaType: "saveAdaptation"
  }, adaptationForm);
  const { oneStatus, fetchOne: fetchOneLayer } = useLayer();
  const { user, hasModule } = useUser();
  const { t } = useTranslation();
  
  const [colors, setColors] = useState([]);
  const [enableEdition, setEnableEdition] = useState(false);

  const initForm = () => {
    let { attributes, structureNameFile, structureNamePattern, properties } = adaptation;

    let format = formatOpts[0];

    if (properties?.margin) {
      changeHandler({ target: {
        name: "type",
        v: typeOpts[2]
      }});
    }

    if (structureNameFile?.indexRules?.format) {
      let auxFormat = (structureNameFile?.indexRules?.format + structureNameFile?.indexRules?.start).substring(1);
      if (auxFormat.length === 1) {
        auxFormat = parseInt(auxFormat);
      }
      format = formatOpts.filter((f) => f.value === auxFormat)?.[0] ?? formatOpts[0];
    }
    
    setForm({
      type: typeOpts?.filter((r) => r?.value === attributes?.type)?.[0] ?? typeOpts[0], 
      bgType: properties?.background?.start === null ? 
        bgTypeOpts[2]
      : 
      (properties?.background?.start === properties?.background?.end ? 
          bgTypeOpts[0]
        : 
          bgTypeOpts[1]
      ),
      renameType: attributes?.structurenamefileLock ? renameTypeOpts[1] : renameTypeOpts[0],
      width: properties?.width,
      height: properties?.height,
      density: properties?.density,
      margin: properties?.margin?.l0 ?? null,
      maxSize: properties?.maxSize,
      mimetype:  properties?.mimetype ? 
        mimeTypeOpts.filter((r) => r.value === properties.mimetype).pop()
      :
        mimeTypeOpts[0],
      foldingPc: {
        label: appColumnKeyProps?.name,
        value: "pc"
      },
      separator: separatorOpts.filter(
        (a) => structureNameFile.pattern?.default === `pc(?)/${a.value}/:index:`
      )?.[0] ?? separatorOpts[0],
      format: format,
      folding: foldingOpts.filter((r) => r.value === structureNamePattern)?.[0] ?? foldingOpts[0]
    });
    
    if (properties?.background?.start) setColors((v) => {
      v[0] = properties.background.start;
      
      if (properties?.background?.end) v[1] = properties.background.end;
      return v;
    });
      
  };

  const submitHandler = async () => {
    let { bgType, density, height, maxSize, mimetype, width, format, type } = form;
    let derivedFrom;
    let master;
    let background = {
      start: null,
      end: null
    };

    switch (type?.value) {
      case CHANGE_BG:
        master = mastersData?.filter(
          (r) => r.attributes.withBackground === false && !!r.derivedFrom && r?.key?.includes("nobg-")
        ).pop();
        derivedFrom = master._id;
        break;
      case WITH_MARGINS:
        master = mastersData?.filter(
          (r) => r.attributes.withBackground === false && !!r.derivedFrom && r?.key?.includes("nobgtrim-")
        )?.pop();
        derivedFrom = master._id;
        break;
      case REDIMENSION:
      default:
        master = mastersData?.filter((r) => r.attributes.withBackground === true)?.pop();
        derivedFrom = master._id;
    }

    if ([CHANGE_BG, WITH_MARGINS].indexOf(type?.value) !== -1 && bgType?.value !== TRANSPARENT) {
      background = { start: colors?.[0] ?? null, end: colors?.[1] ?? null };
    }

    if (type?.value !== REDIMENSION && bgType?.value === TRANSPARENT) {
      mimetype = mimeTypeOpts[1];
    }

    const data = {
      attributes: {
        type: type?.value,
        structurenamefileLock: (form?.renameType?.value !== STANDARD)
      },
      derivedFrom,
      structureNamePattern: form?.folding?.value,
      properties: {
        background,
        density,
        height,
        margin: (type?.value === WITH_MARGINS ? {
          l0: form?.margin ?? 0,
          l1: form?.margin ?? 0,
          l2: form?.margin ?? 0,
          l3: form?.margin ?? 0
        }: null),
        maxSize,
        mimetype: mimetype.value,
        width
      }
    };

    if (form.renameType?.value === STANDARD) {
      data.structureNameFile = {
        indexRules: {
          start: typeof format?.value === "string" ? parseInt(format?.value?.split("")?.pop()) : format?.value,
          format: ["01", "00"].indexOf(format?.value) !== -1 ? "00" : "0"
        },
        pattern: {
          default: `${form?.foldingPc?.value}(?)/${form?.separator?.value}/:index:`,
          byPosition: Array.from({ length: 15 }, () => (null)).map(
            () => `${form?.foldingPc?.value}(?)/${form?.separator?.value}/:index:`
          )
        }
      };
    }
    
    await updateAdaptation(layoutKey, adaptation._id, data);
    return setEnableEdition(false);
  };

  useEffect(() => {
    if (adaptation) initForm();
  }, [adaptation]);
  
  return (
    <Card className={prefix}
      header={<FlexComponent className={`${prefix}__header`}>
        <div>
          <div className="--key">{adaptation?.key}</div>
          <div className="--name">{adaptation?.name}</div>
        </div>
        <div>
          {!enableEdition && user.canDoIt($ADAPTATION_EDIT) &&
            <Button
              content={t("common:words.config")}
              onClick={() => setEnableEdition(true)}
              type="underline"
              xs
            />
          }
          {enableEdition && <>
            <Button
              content="Guardar"
              disabled={disabled || oneStatus === LOADING}
              onClick={submitHandler}
              type="success"
              xs
            />
            <Button
              content={t("common:form.cancel")}
              onClick={() => {
                setEnableEdition(false);
                initForm();
              }}
              type="underline"
              xs
            />
          </>}
        </div>
      </FlexComponent>}
    >
      <div className={`${prefix}__content`}>
        <div className="--dimensions">
          <DimensionPreview
            className="--preview"
            derivedFrom={""}
            h={form?.height}
            w={form?.width}
            margin={[form?.margin, form?.margin, form?.margin, form?.margin]}
            background={backgroundColor({
              ...adaptation,
              attributes: {
                ...adaptation.attributes,
                type: form?.type?.value
              },
              bgType: form?.bgType?.value,
              properties: {
                ...adaptation.properties,
                background: {
                  start: adaptation?.properties?.background?.start && colors[0] ? colors[0] : null,
                  end: adaptation?.properties?.background?.end && colors[1] ? colors[1] : null
                }
              }
            })}
            fadeType="radial"
            overlayer={hasModule(WATERMARK_MODULE) && enableEdition && (!adaptation?.properties?.layerUrl ? 
                <>
                  <div
                    className="--addBtn"
                    onClick={() => {
                      setWmCanvasEditor({
                        adaptation,
                        layoutKey
                      });
                      openWmCanvasEditor();
                    }}
                  >
                    {t("settings:layoutAdaptations.watermark.addBtn")}
                  </div>
                  <div></div>
                </>
              : <>
                <div
                  className="--editBtn"
                  onClick={async () => {
                    let { data } = await fetchOneLayer({ w: { _id: adaptation.adaptationLayerId }});
                    setWmCanvasEditor({
                      adaptation,
                      layer: data?.[0],
                      layoutKey
                    }); 
                    openWmCanvasEditor();
                  }}
                >
                  {t("settings:layoutAdaptations.watermark.editBtn")}
                </div>
                <div
                  className="--deleteBtn"
                  onClick={async () => deleteWatermark(layoutKey, adaptation._id)}
                >
                  <NubeskIcons icon="delete" />{t("settings:layoutAdaptations.watermark.deleteBtn")}
                </div>
              </>
            )}
            overlayerImg={hasModule(WATERMARK_MODULE) ? adaptation?.properties?.layerUrl : undefined}
          />

          <div className="--form">
            <div className="--width">
              <label>{t("settings:layoutAdaptations.fields.width.label")}</label>
              <div className="--form-control">
                <InputTest 
                  disabled={!enableEdition}
                  maxLength={4}
                  name="width"
                  onChange={changeHandler}
                  value={form?.width}
                />
                <span>px</span>
              </div>
            </div>

            <div className="--height">
              <label>{t("settings:layoutAdaptations.fields.height.label")}</label>
              <div className="--form-control">
                <InputTest 
                  disabled={!enableEdition}
                  maxLength={4}
                  name="height"
                  onChange={changeHandler}
                  value={form?.height}
                />
                <span>px</span>
              </div>
            </div>

            <div className="--type">
              <label>{t("settings:layoutAdaptations.fields.adaptationType.label")}</label>
              <div>
                {!!form?.type && <Select
                  disabled={!enableEdition}
                  value={form?.type}
                  onChange={(v) => changeHandler({
                    target: {
                      name: "type",
                      value: v
                    }
                  })}
                  options={typeOpts}
                  xs
                />}
              </div>
            </div>

            <div className="--resolution">
              <label>{t("settings:layoutAdaptations.fields.density.label")}</label>
              <div className="--form-control">
                <InputTest 
                  disabled={!enableEdition}
                  maxLength={4}
                  name="density"
                  onChange={changeHandler}
                  value={form?.density}
                />
                <span>dpi</span>
              </div>
            </div>

            {form?.mimetype?.value === mimeTypeOpts[0].value &&
              <div className="--size">
                <label>{t("settings:layoutAdaptations.fields.maxSize.label")}</label>
                <div className="--form-control">
                  <InputTest 
                    disabled={!enableEdition}
                    maxLength={4}
                    name="maxSize"
                    onChange={changeHandler}
                    value={form?.maxSize}
                  />
                  <span>kb</span>
                </div>
              </div>
            }

            <div className="--extension">
              <label>{t("settings:layoutAdaptations.fields.extention.label")}</label>
              {!!form?.bgType && (
                form?.type?.value !== REDIMENSION && form?.bgType?.value === TRANSPARENT ?
                  <Select
                    disabled={!enableEdition}
                    key="mimeTypePng"
                    name="extension"
                    options={[mimeTypeOpts[1]]}
                    value={mimeTypeOpts[1]}
                    xs
                  />
                :
                  <Select
                    disabled={!enableEdition}
                    key="mimeType"
                    name="extension"
                    options={mimeTypeOpts}
                    onChange={(v) => changeHandler({
                      target: {
                        name: "mimetype",
                        value: v
                    }})}
                    value={form?.mimetype}
                    xs
                  />
                )
              }
            </div>
            {form?.type?.value === WITH_MARGINS &&
              <div className="--margins">
                <label>{t("settings:layoutAdaptations.margins")}</label>
                <div className="--form-control">
                  <InputTest 
                    disabled={!enableEdition}
                    maxLength={3}
                    name="margin"
                    onChange={changeHandler}
                    value={form?.margin}
                    xs
                  />
                  <span>px</span>
                </div>
              </div>
            }

            {[CHANGE_BG, WITH_MARGINS].includes(form?.type?.value) &&
              <>
                <div className="--type-bg">
                  <label>{t("settings:layoutAdaptations.fields.bgImage.label")}</label>
                  <div>
                    {!!form?.bgType && <Select
                      disabled={!enableEdition}
                      onChange={(v) => changeHandler({
                        target: {
                          name: "bgType",
                          value: v
                        }
                      })}
                      options={bgTypeOpts}
                      xs
                      value={form?.bgType}
                    />}
                  </div>
                </div>
                {form?.bgType?.value !== TRANSPARENT &&
                  <div className="--color">
                    <label>{t("settings:layoutAdaptations.fields.bgColor.label")}</label>
                    <div className="--form-control">
                      <ButtonColor 
                        disabled={!enableEdition}
                        onChange={(c) => {
                          let aux = [...colors];
                          aux[0] = c.hex;
                          if (form?.bgType?.value === SOLID) aux[1] = c.hex;
                          setColors(aux);
                        }}
                        placement="top"
                        value={colors[0]}
                      />
                      {form?.bgType?.value === RADIAL && 
                        <ButtonColor 
                          disabled={!enableEdition}
                          onChange={(c) => {
                            let aux = [...colors];
                            aux[1] = c.hex;
                            setColors(aux);
                          }}
                          placement="top"
                          value={colors[1]}
                        />
                      }
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>

        <div className="--renaming">
          <div className="--type">
            <label>{t("settings:layoutAdaptations.fields.renameType.label")}</label>
            {!!form?.renameType && <Select
              disabled={!enableEdition || form?.renameType?.value === CUSTOM}
              onChange={(v) => changeHandler({
                target: {
                  name: "renameType",
                  value: v
                }
              })}
              options={renameTypeOpts}
              value={form?.renameType}
              xs
            />}
          </div>

          {false && form?.renameType?.value === CUSTOM &&
            <div className="--empty-first">
              <label>Primero vacío</label>
              <CheckBox disabled />
            </div>
          }

          <div className={`--nomenclature ${form?.renameType?.value !== STANDARD ? "--only-one": ""}`}>
            <label>{t("settings:layoutAdaptations.fields.rename.label")}</label>
            {form?.renameType?.value === STANDARD ?
              <div className="--form-control --space-between">
                {!!form?.renameType && <Select
                  disabled={!enableEdition}
                  value={form?.foldingPc}
                  onChange={(v) => changeHandler({
                    target: {
                      name: "foldingPc",
                      value: v
                    }
                  })}
                  options={[{
                    label: appColumnKeyProps?.name,
                    value: "pc"
                  }]}
                  xs
                />}
                <span>+</span>
              </div>
              :
              <div className="--email">{t("settings:layoutAdaptations.contact")} <a href={`mailto:soporte@nubesk.com.mx?subject=Cambios%20en%20el%20renombrado%20de%20la%20adaptaci%C3%B3n%20${adaptation?.key}%20-%20${appLookAndFeel?.account?.name}&body=Hola%2C%0D%0A%0D%0ASolicito%20me%20ayuden%20a%20modificar%20el%20renombrado%20para%20la%20adaptaci%C3%B3n%20${adaptation?.key}%20en%20la%20cuenta%20de%20${appLookAndFeel?.account?.name}`}>soporte@nubesk.com.mx</a></div>
            }
          </div>

          {form?.renameType?.value === STANDARD &&
            <>
              <div className="--separator">
                <label>{t("settings:layoutAdaptations.fields.split.label")}</label>
                <div className="--form-control --space-between">
                  {!!form?.separator && <Select
                    disabled={!enableEdition}
                    onChange={(v) => changeHandler({
                      target: {
                        name: "separator",
                        value: v
                      }
                    })}
                    options={separatorOpts}
                    value={form?.separator}
                    xs
                  />}
                  <span>+</span>
                </div>
              </div>

              <div className="--start ">
                <label>{t("settings:layoutAdaptations.fields.start.label")}</label>
                {!!form?.format && 
                  <Select
                    disabled={!enableEdition}
                    onChange={(v) => changeHandler({
                      target: {
                        name: "format",
                        value: v
                      }
                    })}
                    options={formatOpts}
                    value={form?.format}
                    xs
                  />
                }
              </div>
            </>
          }

          <div className="--folding ">
            <label>{t("settings:layoutAdaptations.fields.folding.label")}</label>
            {!!form?.folding && <Select
              disabled={!enableEdition}
              onChange={(v) => changeHandler({
                target: {
                  name: "folding",
                  value: v
                }
              })}
              options={foldingOpts}
              value={form?.folding}
              xs
            />}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ChannelAdaptationsCard;
