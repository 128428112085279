import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, NubeskIcons } from "components/atoms";
import { NbskEditor } from "components/organism";
import { useAdaptation, useLayer } from "_hooks";
import { WATERMARK } from "_constants/layer.constants";
import { LANDSCAPE, PORTRAIT, SQUARE } from "_constants/adaptation.constants";

const prefix = "t-watermarker-canvas-editor";

const WatermarkerCanvasEditor = ({}) => {

  const { wmCanvasEditor: { data }, closeWmCanvasEditor, setWmCanvasEditor, updateAdaptation } = useAdaptation();
  const { createLayer, updateLayer } = useLayer();
  const { t } = useTranslation();

  const [canvasLocal, setCanvasLocal] = useState();
  const [type, setType] = useState();

  const close = () => {
    setWmCanvasEditor(null);
    closeWmCanvasEditor();
  };

  const save = async () => {
    let response;
    try {
      let json = JSON.stringify(canvasLocal.toJSON(["nbskProperties"]));

      let template = {[type]: json };

      if (data?.adaptation?.layerId) {
        response = await updateLayer(data?.adaptation?.layerId, {
          template,
          property: {
            canvas: {
              height: data.adaptation?.properties?.height,
              width: data.adaptation?.properties?.width
            }
          }
        });
      } else {
        response = await createLayer({
          name: `${data?.adaptation?.key}_${data?.adaptation?.name}_wm`,
          property: {
            canvas: {
              height: data.adaptation?.properties?.height,
              width: data.adaptation?.properties?.width
            }
          },
          type: WATERMARK,
          template
        });
      }

      response = await updateAdaptation(data.layoutKey, data?.adaptation?._id, {
        adaptationLayerId: response?.data?.[0]?._id
      });

      setWmCanvasEditor(null);
      closeWmCanvasEditor();
      return response?.data;
    } catch (e) {}
  };

  useEffect(() => {
    if (data?.adaptation?.properties?.width === data?.adaptation?.properties?.height) {
      setType(SQUARE);
    } else if (data?.adaptation?.properties?.width > data?.adaptation?.properties?.height) {
      setType(LANDSCAPE);
    } else {
      setType(PORTRAIT);
    }
  }, [])

  return (
    <div className={prefix}>
      <div className={`${prefix}__header`} >
        <div>
          <div className="--data">
            <Button
              leftIcon={<NubeskIcons icon="arrowLeft" />}
              onClick={() => close()}
              style={{ width: "max-content" }}
              type="color"
            />
            <div className="--adaptation-key">
              <Badge border="purple" content={data?.adaptation?.key} />
            </div>
            <div className="--adaptation-name">
              {data?.adaptation?.name} | {t("settings:layoutAdaptations.watermark.label")}
            </div>
          </div>
        </div>

        <div>
        <Button
            content={t("common:form.cancel")}
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
            sm
            type="primary"
          />
          <Button
            content={t("settings:layoutAdaptations.watermark.save")}
            onClick={ (e) => {
              e.preventDefault();
              save();
            }}
            sm
            type="success"
          />
        </div>
      </div>
      
      {type &&
        <NbskEditor
          canvasWidth={data?.adaptation?.properties?.width}
          canvasHeight={data?.adaptation?.properties?.height}
          json={data?.layer?.template?.[type] ? JSON.parse(data?.layer?.template?.[type]) : undefined}
          onInit={({ canvas }) => setCanvasLocal(canvas)}
        />
      }
    </div>
  );
};

export default WatermarkerCanvasEditor;
