import initialState from "../multivende.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as Multivende } from '../../multivende.constants';

const sync = {
  [Multivende.SYNC_STARTED]: (state) => ({
    ...state,
    sync: {
      ...state.sync,
      status: LOADING
    }
  }),
    
  [Multivende.SYNC_FAIL]: (state, { payload }) => ({
    ...state,
    sync: {
      ...state.sync,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [Multivende.SYNC_SUCCESS]: (state, { payload }) => ({
    ...state,
    sync: {
      ...state.sync,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  
  [Multivende.SYNC_RESET]: (state) => ({
    ...state,
    sync: initialState.sync
  })
};

export default sync;
