export const reduxActions = {

  CONNECT_TO_FAIL: "multivende/connect_to_fail",
  CONNECT_TO_RESET: "multivende/connect_to_reset",
  CONNECT_TO_STARTED: "multivende/connect_to_started",
  CONNECT_TO_SUCCESS: "multivende/connect_to_success",

  AUTHORIZE_FAIL: "multivende/authorize_fail",
  AUTHORIZE_RESET: "multivende/authorize_reset",
  AUTHORIZE_STARTED: "multivende/authorize_started",
  AUTHORIZE_SUCCESS: "multivende/authorize_success",

  CONFIG_FAIL: "multivende/config_fail",
  CONFIG_RESET: "multivende/config_reset",
  CONFIG_STARTED: "multivende/config_started",
  CONFIG_SUCCESS: "multivende/config_success",

  POST_FAIL: "multivende/post_fail",
  POST_RESET: "multivende/post_reset",
  POST_STARTED: "multivende/post_started",
  POST_SUCCESS: "multivende/post_success",

  PUBLISH_FAIL: "multivende/publish_fail",
  PUBLISH_RESET: "multivende/publish_reset",
  PUBLISH_STARTED: "multivende/publish_started",
  PUBLISH_SUCCESS: "multivende/publish_success",

  SYNC_FAIL: "multivende/sync_fail",
  SYNC_RESET: "multivende/sync_reset",
  SYNC_STARTED: "multivende/sync_started",
  SYNC_SUCCESS: "multivende/sync_success",

  SYNC_PRODUCT_FAIL: "multivende/sync_product_fail",
  SYNC_PRODUCT_RESET: "multivende/sync_product_reset",
  SYNC_PRODUCT_STARTED: "multivende/sync_product_started",
  SYNC_PRODUCT_SUCCESS: "multivende/sync_product_success"
};

export const CONNECTED_STATUS = "connected";
export const DISCONNECTED_STATUS = "disconnected";
export const SYNC_STATUS_PENDING = "pending";
export const SYNC_STATUS_PUSHED = "pushed";
