import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const useToolbar = () => {
  const { pathname } = useLocation();

  const [toolbar, setToolbar] = useState("");

  useEffect(() => {
    const getToolbar = () => {
      let section = "";
      let split = pathname.split('/')
      let root = split[1];
      switch (root) {
        case "catalogs":

        case "products":
          section = root;
          if (["photos", "copy", "publish"].indexOf(split[3]) !== -1) {
            section = split[3];
          }
          break;
        default:
          section = root;
          break;
      }
      
      return section;
    };
    
    setToolbar(getToolbar());
  }, [pathname]);
  
  return {
    toolbar
  };
};

export default useToolbar;
