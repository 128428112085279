import { useDispatch, useSelector } from "react-redux";
import { config, conn, post } from "./store/multivende.actions";
import {
  CONNECTED_STATUS,
  DISCONNECTED_STATUS,
  reduxActions as MV,
  SYNC_STATUS_PENDING,
  SYNC_STATUS_PUSHED
} from "./multivende.constants";
import logo from "./logo.png";

const useMultivende = () => {
  const dispatch = useDispatch();
  const {
    data: authorizeData,
    error: authorizeError,
    status: authorizeStatus
  } = useSelector((s) => s?.module?.multivende?.authorize);

  const {
    data: configData,
    error: configError,
    status: configStatus
  } = useSelector((s) => s?.module?.multivende?.config);

  const {
    data: syncData,
    error: syncError,
    status: syncStatus
  } = useSelector((s) => s?.module?.multivende?.sync);

  const {
    data: publishData,
    error: publishError,
    status: publishStatus
  } = useSelector((s) => s?.module?.multivende?.publish);

  const { 
    data: postData,
    error: postError,
    status: postStatus
  } = useSelector((s) => s?.module?.multivende?.post);

  const fetchConfig = () => dispatch(config());

  const save = (data, extras) => {
    let match = [];

    for (let i in data) {
      match.push( {
        multivendeMarketplaceId: i,
        nubeskAdaptationId: data[i]?.value,
        config: {
          syncAttributes: data[i]?.syncAttributes ?? false,
          syncImages: data[i]?.syncImages ?? false
        }
      });
    }

    return dispatch(post(
      { c: [{ rules: { match }}] }, 
      { type: "configuration" }, {
        reqType: "put",
        feedback: {
          error: {
            area: "module:multivende.settings.feedback.save.area",
            info: "module:multivende.settings.feedback.save.error"
          },
          success: {
            area: "module:multivende.settings.feedback.save.area",
            info: "module:multivende.settings.feedback.save.success"
          }
        },
        ...extras
      }
    ));
  }

  const checkSaved = (d) => {
    let data;
    if (d) {
      for (let i in d) {
        if (!data) data = {};
        let { syncAttributes, syncImages } = d[i]?.config ?? { syncAttributes: false, syncImages: false};
        data[d[i]?.multivendeMarketplace?._id] = {
          value: d[i]?.nubeskAdaptation,
          syncImages,
          syncAttributes
        };
      }
    }
    
    return data;
  };

  const connectTo = (data, extras) => dispatch(conn(data, {
    type: "authorization"
  }, {
    feedback: {
      error: {
        area: "Plugin Multivende",
        info: "Problema en la conexión",
      },
      success: {
        area: "Plugin Multivende",
        info:"Conectado",
      }
    },
    ...extras
  }));

  const publish = (data, extras = {}) => dispatch(post(
    data, {}, {
      endpoint: "/plugin/multivende/api/product/nbskfiles",
      types: [MV.PUBLISH_STARTED, MV.PUBLISH_SUCCESS, MV.PUBLISH_FAIL],
      feedback: {
        error: {
          area: "module:multivende.publish.feedback.area",
          info: "module:multivende.publish.feedback.error"
        },
        success: {
          area: "module:multivende.publish.feedback.area",
          info: "module:multivende.publish.feedback.success"
        }
      },
      ...extras
    }
  ));

  const syncOneProductMv = (data, extras = {}) =>  dispatch(post(
    data, {}, {
      endpoint: "/plugin/multivende/api/product",
      types: [MV.SYNC_PRODUCT_STARTED, MV.SYNC_PRODUCT_SUCCESS, MV.SYNC_PRODUCT_FAIL],
      feedback: {
        error: {
          area: "module:multivende.syncProduct.feedback.area",
          info: "module:multivende.syncProduct.feedback.error"
        },
        success: {
          area: "module:multivende.syncProduct.feedback.area",
          info: "module:multivende.syncProduct.feedback.success"
        }
      },
      ...extras
    }
  ));

  const syncProductsMv = (data = {}, extras = {}) =>  dispatch(post(
    {}, { syncMasterfiles: true, syncAttributes: true }, {
      endpoint: "/plugin/multivende/sync/products",
      types: [MV.SYNC_STARTED, MV.SYNC_SUCCESS, MV.SYNC_FAIL],
      feedback: {
        error: true,
        success: true
      },
      ...extras
    }
  ));
  
  return {
    authorizeData,
    authorizeError,
    authorizeStatus,
    checkSaved,
    connectTo,
    configData,
    configError,
    configStatus,
    fetchConfig,
    logo,
    postData,
    postError,
    postStatus,
    publish,
    publishData,
    publishError,
    publishStatus,
    save,
    syncData,
    syncError,
    syncStatus,
    syncOneProductMv,
    syncProductsMv,
    CONNECTED_STATUS,
    DISCONNECTED_STATUS,
    SYNC_STATUS_PENDING,
    SYNC_STATUS_PUSHED
  };
};

export default useMultivende;
