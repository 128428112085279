import { useDispatch } from "react-redux";
import { matchPath } from "react-router-dom";
import { changeToolbar } from "_redux/ui/ui.actions";

const useSettings = () => {
  const dispatch = useDispatch();

  const toolbarSwitch = (pathname, path) => {

    if (matchPath(pathname, {
      path: `${path}/data-fields`
    })) {
      dispatch(changeToolbar("property"));
    
    }  else if (matchPath(pathname, {
      path: `${path}/billing`
    })) {
      dispatch(changeToolbar("billing"));
    
    } else if (matchPath(pathname, {
      path: `${path}/layers`
    })) {
      dispatch(changeToolbar("layers"));
    
    } else if (matchPath(pathname, {
      path: `${path}/users`
    })) {
      dispatch(changeToolbar("usersPanel"));
    
    } else if (matchPath(pathname, {
      path: `${path}/sales-channels/:key/adaptations`
    })) {
      dispatch(changeToolbar("channelAdaptations"));
    
    } else if (matchPath(pathname, {
      path: `${path}/sales-channels/:key/attributes`
    })) {
      dispatch(changeToolbar("channelAttributes"));
    
    } else if (matchPath(pathname, {
      path: `${path}/sales-channels`
    })) {
      dispatch(changeToolbar("salesChannel"));
    } else {
      dispatch(changeToolbar(""));
    }
  };

  return {
    toolbarSwitch
  };
};

export default useSettings;
