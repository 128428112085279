import initialState from "../multivende.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as Multivende } from '../../multivende.constants';

const post = {
  [Multivende.POST_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),
    
  [Multivende.POST_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [Multivende.POST_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [Multivende.POST_RESET]: (state) => ({
    ...state,
    post: initialState.post
  })
};

export default post;
