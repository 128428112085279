import initialState from "../multivende.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as Multivende } from '../../multivende.constants';

const publish = {
  [Multivende.PUBLISH_STARTED]: (state) => ({
    ...state,
    publish: {
      ...state.publish,
      status: LOADING
    }
  }),
    
  [Multivende.PUBLISH_FAIL]: (state, { payload }) => ({
    ...state,
    publish: {
      ...state.publish,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [Multivende.PUBLISH_SUCCESS]: (state, { payload }) => ({
    ...state,
    publish: {
      ...state.publish,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  
  [Multivende.PUBLISH_RESET]: (state) => ({
    ...state,
    publish: initialState.publish
  })
};

export default publish;
