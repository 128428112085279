import { useProduct, useUser } from "_hooks";
import Accordion from "../Accordion/Accordion";
import useMultivende from "module/Multivende/useMultivende";
import { PublishRows } from "module/Multivende/components";
import { MULTIVENDE_PLUGIN } from "_constants/app.constants";

const prefix = "o-details-publish";

const DetailsPublish = () => {
  const { hasModule } = useUser();
  const { logo: logoMv } = useMultivende();
  const { detailsData: product } = useProduct();

  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        {product && hasModule(MULTIVENDE_PLUGIN) &&
          <Accordion
            title="Multivende"
            headerLeft={<img src={logoMv} 
              alt="" 
              className="--logo"
            />}
            headerRight={null}
            isOpen={true}
            content={<PublishRows 
              product={product}
            />}
          />
        }
      </div>
    </div>
  );
};

export default DetailsPublish;
