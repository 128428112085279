import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { FlexComponent } from "components/templates";
import { Button, Title, CircleStatus } from "components/atoms";
import { NubeskIcons } from "components/atoms";
import { selectProductDetails } from "_redux/product/product.selector";
import { ColumnsTemplate } from "components/templates";
import { useTooltip, useUser } from "_hooks";
import { selectDetails, selectPrevious } from "_redux/ui/ui.selector";
import { changeToolbar, previousPage } from "_redux/ui/ui.actions";
import forceDownload from "_utils/files/forceDownload";
import { MULTIVENDE_PLUGIN } from "_constants/app.constants";

const prefix = "o-details-toolbar";

const DetailsToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const product = useSelector(selectProductDetails);
  const history = useHistory();
  const previous = useSelector(selectPrevious);

  const [loadingImage, setLoadingImage] = useState(false);

  const { currentImage, images } = useSelector(selectDetails);

  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });

  const { hasModule } = useUser();

  const downloadImage = async () => {
    setLoadingImage(true);
    await forceDownload(
      images[currentImage].baseKeyS3Original,
      images[currentImage]?.data?.namefile,
      images[currentImage]?.mimeType
    )
    setLoadingImage(false);
  };
  
  const handleRoute = (section) => {
    let path = pathname.split("/");
    path[path.length - 1] = section;

    history.replace({
      pathname: path.join("/"),
      search,
    });
  };

  const goBack = () => {
    dispatch(changeToolbar("products"));
    let prev = Object.assign({}, previous);

    if (matchPath(prev?.location?.pathname, {
      path: "/products/catalog/:key",
      strict: true
    })) {
      dispatch(previousPage({}));
    }

    history.push({
      pathname: `${prev?.location?.pathname || "/products"}`  ,
      search: `${prev?.location?.search || ""}`
    });
  }

  return (
    <ColumnsTemplate
      leftWidth="45%"
      className={prefix}
      leftComponent={
        <div className={`${prefix}__flex left`}>
          <FlexComponent gap={0}>
            <Button
              content=""
              leftIcon={<NubeskIcons icon="arrowLeft" />}
              type="color"
              style={{ width: "max-content" }}
              onClick={goBack}
            />
            <Title content={product?.key} secondary fontSize={22} />
          </FlexComponent>

          <FlexComponent sm>
            <Button
              content={t("products:details.download")}
              type={"primary"}
              onClick={downloadImage}
              disabled={currentImage === undefined || !images.length || loadingImage}
            />
            <div {...injectedProps} style={{ width: 30 }}>
              <Button
                content={<NubeskIcons icon="dots" />}
                onClick={() => {}}
                type="color"
                disabled
              />
            </div>
          </FlexComponent>
        </div>
      }
      rightComponent={
        <div className={`${prefix}__flex right`}>
          <FlexComponent sm>
            <Button
              leftIcon={<NubeskIcons icon="photos" />}
              content={t("products:details.imagery.tab")}
              rightIcon={
                <CircleStatus status={product?.process?.reviewMaster} />
              }
              type="tabs"
              isActive={pathname.includes("photos")}
              onClick={() => handleRoute("photos")}
            />
            <Button
              leftIcon={<NubeskIcons icon="copy" />}
              content={t("products:details.info.tab")}
              rightIcon={<CircleStatus status={product?.process?.reviewCopy} />}
              isActive={pathname.includes("copy")}
              type="tabs"
              onClick={() => handleRoute("copy")}
            />

            {hasModule(MULTIVENDE_PLUGIN) &&
              <Button
                leftIcon={<NubeskIcons icon="moduleSend" />}
                content={t("products:details.publish.tab")}
                isActive={pathname.includes("publish")}
                type="tabs"
                onClick={() => handleRoute("publish")}
              />
            }

          </FlexComponent>

          <div {...injectedProps}>
            <Button
              disabled
              content={<NubeskIcons icon="message" />}
              className={`${prefix}__tasks`}
              type="color"
              sqr
              isActive={pathname.includes("task")}
              onClick={() => {
                /* handleRoute("task") */
              }}
            />
          </div>
        </div>
      }
    />
  );
};

export default DetailsToolbar;
