import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FlexComponent } from "components/templates";
import NubeskIcons from "../NubeskIcons/NubeskIcons";

const prefix = "a-button";

const Button = ({
  className,
  content = "",
  fullwidth = false,
  isActive,
  leftIcon,
  onClick = () => {},
  qty,
  sm = false,
  spin,
  sqr,
  rightIcon,
  type,
  xs = false,
  ...props
}) => {
  const classForComponent = classNames(prefix, {
    [`${prefix}--${type}`]: type,
    "is-active": isActive && type !== "dropdown",
    "is-fullwidth": fullwidth,
    "has-qty": typeof qty === "number",
    "is-small": sm,
    "is-xsmall": xs,
    "is-square": sqr,
    "is-selected": isActive && type === "dropdown",
    [className]: className,
  });

  return (
    <button className={classForComponent} onClick={onClick} {...props}>
      <FlexComponent sm={xs ? false : true} xs={xs}>
        {leftIcon}
        {typeof content == "string" ? (
          <span className={`${prefix}__span`}>{content}</span>
        ) : (
          content
        )}
        {rightIcon}
        {spin && <NubeskIcons className="u-spin" icon="spin" />}
      </FlexComponent>
      {qty ? <span className={`${prefix}__qty`}>{qty}</span> : null}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isActive: PropTypes.bool,
  leftIcon: PropTypes.node,
  onClick: PropTypes.func,
  qty: PropTypes.number,
  rightIcon: PropTypes.node,
  sm: PropTypes.bool,
  spin: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
