import initialMultivendeState from "./multivende.initialState";
import authorize from "./reducer/authorize";
import config from "./reducer/config";
import post from "./reducer/post";
import publish from "./reducer/publish";
import sync from "./reducer/sync";

const multivendeReducer = (state = initialMultivendeState, action) => {
  const reducers = {
    ...authorize,
    ...config,
    ...post,
    ...publish,
    ...sync
  };
  
  let { type } = action;
  
  if (reducers[type] === undefined) return state;
  
  return reducers[type](state, action);
};

export default multivendeReducer;
